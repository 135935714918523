import { PaymentElement, useElements, useStripe, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import InformationIcon from 'asset/Icons/InformationIcon';
import { MdGlobalButton } from 'components/global';
import LoadingScreen from 'components/LoadingScreen';
import { paymentContext } from 'contexts/payment';
import { SET_DEFAULT_STATE } from 'contexts/payment/reducer/types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useCreatePaygPaymentIntentMutation,
  useCreateSubscriptionWithStripeMutation,
  useCreateTopupPaymentIntentMutation,
  useUpdateSubscriptionWithStripeMutation,
  useValidatePaymentIntentMutation,
} from 'redux/featureApi/stripePaymentApiSlice';
import { me } from 'utils/service';
import { thousandFormatter } from 'utils/thousandFormatter';
import { showToastMessage } from 'utils/Toast';

const StripeCheckout = () => {
  const { state, dispatch } = useContext(paymentContext);
  const { plan, currentPlan: userCurrentPlan, quantity, price, currency } = state;

  const [createPaygPaymentIntent, { isLoading: creatingPaygPaymentIntent }] = useCreatePaygPaymentIntentMutation();
  const [createTopupPaymentIntent, { isLoading: creatingTopupPaymentIntent }] = useCreateTopupPaymentIntentMutation();
  const [createSubscriptionWithStripe, { isLoading: creatingSubscription }] = useCreateSubscriptionWithStripeMutation();
  const [updateSubscriptionWithStripe, { isLoading: updatingSubscription }] = useUpdateSubscriptionWithStripeMutation();

  const [clientSecret, setClientSecret] = useState(null);
  const [stripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY));
  const [isSubscription, setIsSubscription] = useState(true);
  const [processingPayment, setProcessingPayment] = useState(false);

  const [prevQuantity, setPrevQuantity] = useState('');

  const getClientSecret = async (stateChanged) => {
    // Prevent unnecessary calls when quantity has not changed
    if (plan.payg && quantity === prevQuantity) {
      return;
    }

    // Just first reset the client secret to avoid paying/validating old client secret
    setClientSecret(null);

    if (plan.payg && price >= 0.5) {
      await createPaygPaymentIntent({ quantity: quantity })
        .unwrap()
        .then((res) => {
          setClientSecret(res.data.clientSecret);
          setPrevQuantity(quantity);
        })
        .catch((err) => {
          showToastMessage({
            type: 'error',
            title: 'Payment Error',
            description: err.data.error || 'An error occurred, Please try again',
          });
        });
    } else if (plan.topup) {
      await createTopupPaymentIntent({ quantity: quantity })
        .unwrap()
        .then((res) => {
          setClientSecret(res.data.clientSecret);
        })
        .catch((err) => {
          showToastMessage({
            type: 'error',
            title: 'Payment Error',
            description: err.message || 'An error occurred, Please try again',
          });
        });
    } else if (plan.stripePlanId && clientSecret) {
      if (stateChanged) {
        handleSubscriptionLogic();
      }
    }
  };

  const handleSubscriptionLogic = async () => {
    setProcessingPayment(true);
    // first check if user is trying to subscribe to a plan that is already active
    if (!userCurrentPlan?.isPaidUser) {
      createSubscriptionWithStripe({ priceId: plan.stripePlanId })
        .unwrap()
        .then((res) => {
          setClientSecret(res.data.clientSecret);
          setIsSubscription(false);
        })
        .catch((err) => {
          showToastMessage({
            type: 'error',
            title: 'Payment Error',
            description: err.data.error || 'An error occurred, Please try again',
          });
        })
        .finally(() => {
          setProcessingPayment(false);
        });
    } else if (userCurrentPlan?.stripePlanId === plan.stripePlanId) {
      showToastMessage({
        type: 'error',
        title: 'Subscription Error',
        description: 'You are already subscribed to this plan, please select another',
      });
      setProcessingPayment(false);
      return;
    } else {
      // use update subscription api
      updateSubscriptionWithStripe({ priceId: plan.stripePlanId })
        .unwrap()
        .then((res) => {
          if (res.data.clientSecret) {
            setClientSecret(res.data.clientSecret);
          } else {
            showToastMessage({
              type: 'success',
              title: 'Subscription Updated',
              description: 'Your subscription plan has been updated successfully',
            });
          }
          setIsSubscription(false);
        })
        .catch((err) => {
          showToastMessage({
            type: 'error',
            title: 'Payment Error',
            description: err.data.error || 'An error occurred, Please try again',
          });
        })
        .finally(async () => {
          setProcessingPayment(false);
          await me();
          // navigate('/settings/account/plans');
        });
    }
  };

  useEffect(() => {
    // Fetch clientSecret when component mounts
    getClientSecret();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Fetch clientSecret when state changes
    getClientSecret(true);

    // eslint-disable-next-line
  }, [state]);

  if (plan.payg && (quantity <= 0 || price < 0.5) && currency === 'USD') {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '5px',
        }}
      >
        <InformationIcon width="20" />
        <p>Minimum order price is $0.5</p>
      </div>
    );
  }

  if (plan.stripePlanId && isSubscription) {
    return (
      <MdGlobalButton
        size={'large'}
        htmlType="submit"
        className="payment__button"
        loading={processingPayment}
        onClick={async () => {
          await handleSubscriptionLogic();
        }}
      >
        Continue
      </MdGlobalButton>
    );
  }

  if (
    !clientSecret ||
    creatingPaygPaymentIntent ||
    creatingTopupPaymentIntent ||
    creatingSubscription ||
    updatingSubscription
  ) {
    return <LoadingScreen />;
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <CheckoutForm
        state={state}
        processingPayment={processingPayment}
        setProcessingPayment={setProcessingPayment}
        dispatch={dispatch}
      />
    </Elements>
  );
};

const CheckoutForm = ({
  state: { plan, currency, price, quantity },
  processingPayment,
  setProcessingPayment,
  dispatch,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [validatePaymentIntent] = useValidatePaymentIntentMutation();
  const [paymentDetailsProvided, setPayemntDetailsProvided] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessingPayment(true);

    if (!stripe || !elements) {
      return setProcessingPayment(false);
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://localhost:3000/payment/pay',
      },
      redirect: 'if_required',
    });

    if (result.error) {
      showToastMessage({
        type: 'error',
        title: 'Payment Error',
        description: result.error.message,
      });
    }

    if (result.paymentIntent.status === 'succeeded') {
      handlePaymentSuccess(result);
    }
  };

  const handlePaymentComponentChange = (event) => {
    setPayemntDetailsProvided(event.complete);
  };

  const handlePaymentSuccess = (result) => {
    validatePaymentIntent({ paymentIntentId: result.paymentIntent.id })
      .unwrap()
      .then(async () => {
        showToastMessage({
          type: 'success',
          title: 'Payment Successful',
          description: 'Your payment was successful, your wallet will be credited shortly',
        });
        dispatch({ type: SET_DEFAULT_STATE });
        await me();
        navigate('/settings/account/plans');
      })
      .catch((err) => {
        showToastMessage({
          type: 'error',
          title: 'Payment Error',
          description: err.message,
        });
      })
      .finally(() => {
        setProcessingPayment(false);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement onChange={handlePaymentComponentChange} />
      <MdGlobalButton
        loading={processingPayment}
        disabled={processingPayment || (!plan.stripePlanId && quantity <= 0) || !paymentDetailsProvided}
        size={'large'}
        htmlType="submit"
        className="payment__button"
      >
        Pay {currency === 'USD' ? '$' : '₦'}
        {thousandFormatter(parseFloat(price).toFixed(2))}
      </MdGlobalButton>
    </form>
  );
};

export default StripeCheckout;
