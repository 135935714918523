import { Popconfirm } from 'antd';
import RedirectUrlInput from '../../../../components/emmaccen/modals/RedirectUrlInput';
import { MdTypography } from '../../../../components/global';
import withPopconfirm from '../../../../HOC/withPopconfirm';
import React from 'react';
import { Link } from 'react-router-dom';
import copyIcon from '../../../../asset/Icons/copyIcon.png';
import { cleanString } from 'utils/generics';

const MdLandinPageComp = (props) => {
  const { data } = props;

  const { user } = JSON.parse(localStorage.getItem('userDetails'));
  const userName = cleanString(user.username);
  const campaignId = data._id;
  const campaignTitle = cleanString(data.name);

  return (
    <div className="landingpage_comp">
      <Link to={{ pathname: `/join/${userName}/${campaignTitle}/admin-edit`, search: `?edit=true&id=${campaignId}` }}>
        <MdTypography variant={'btnLight'} style={{ color: '#1c1c1c' }} data-test="edit-btn">
          {' '}
          Edit
        </MdTypography>
      </Link>
      <Popconfirm
        title="Would you like to redirect your users to a particular link after subscribing?"
        onConfirm={props.popConfirm}
        onCancel={props.popCancel}
        okText="Yes"
        cancelText="No"
        // placement="topLeft"
        placement="bottomLeft"
      >
        <div className="landingpage_copy-link">
          <MdTypography variant={'btnLight'} style={{ color: '#383838' }}>
            Copy link
          </MdTypography>
          <img src={copyIcon} alt="copy icon" className="img-fluid ml-1" />
        </div>
      </Popconfirm>
      <RedirectUrlInput
        imVisible={props.inputUrlModal}
        setImVisible={props.setInputUrlModal}
        campaignId={props.campaignId}
        context="joinPage"
        data={data}
      />
    </div>
  );
};

export default withPopconfirm(MdLandinPageComp, 'obj');
