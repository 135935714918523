import { apiAuthSlice } from './apiAuthSlice';

export const paymentApiSlice = apiAuthSlice.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    getPlans: builder.query({
      query: () => ({
        url: `/plans`,
        method: 'GET',
      }),
      providesTags: ['payment'],
      transformResponse: (response) => {
        return response.data.map((plan) => ({
          ...plan,
          displayPrice: {
            NGN: plan.priceInNaira,
            USD: plan.priceInDollars,
            GHS: 0,
          },
        }));
      },
    }),
    getPaygRate: builder.query({
      query: () => ({
        url: `/payment/paystack/transactions`,
        method: 'GET',
      }),
      providesTags: ['payment'],
    }),
    getPaygPromoRate: builder.query({
      query: () => ({
        url: `/payment/paystack/transactions/payg-promo-price`,
        method: 'GET',
      }),
      providesTags: ['payment'],
    }),
    getTopupRate: builder.query({
      query: () => ({
        url: `/payment/paystack/transactions/topup`,
        method: 'GET',
      }),
      providesTags: ['payment'],
    }),
    createPaygTrx: builder.mutation({
      query: (payload) => ({
        url: `/payment/paystack/transactions`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['payment'],
    }),
    createTopupTrx: builder.mutation({
      query: (payload) => ({
        url: `/payment/paystack/transactions/topup`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['payment'],
    }),
    createSubTrx: builder.mutation({
      query: (payload) => ({
        url: `/payment/paystack/subscriptions`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['payment'],
    }),
    updateSubscriptionWithPaystack: builder.mutation({
      query: (payload) => ({
        url: `payment/paystack/subscriptions/update`,
        method: 'PATCH',
        body: payload
      })
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetPlansQuery,
  useGetPaygRateQuery,
  useGetPaygPromoRateQuery,
  useGetTopupRateQuery,
  useCreatePaygTrxMutation,
  useCreateTopupTrxMutation,
  useCreateSubTrxMutation,
  useUpdateSubscriptionWithPaystackMutation
} = paymentApiSlice;
