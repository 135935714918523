import React, { useContext, useEffect, useState } from 'react';
import { Divider } from 'antd';
import './style.scss';
import PAYG from '../../../components/Payment/PaygEl';
import { paymentContext } from '../../../contexts/payment';
import {
  SET_LOADING,
  SET_PLANS,
  SET_CURRENCY,
  SET_PAYG_RATES,
  SET_CURRENT_PLAN,
} from '../../../contexts/payment/reducer/types';
import PlansSection from '../../../components/Payment/PlansSection';
import CheckoutCard from '../../../components/Payment/CheckoutCard';
import LoadingScreen from '../../../components/LoadingScreen';
import { getUserLocation } from 'utils/services/authService';
import { useGetPaygRateQuery, useGetPaygPromoRateQuery, useGetPlansQuery } from 'redux/featureApi/paymentApiSlice';
import usePaygPromoDates from 'hooks/usePaygPromoDates';
import { me } from 'utils/service';
import { Helmet } from 'react-helmet-async';

export default function PayComponent() {
  const { withinPromoPeriod } = usePaygPromoDates();
  const [isLoading, setIsLoading] = useState(true);

  const {
    state: { loading },
    dispatch,
  } = useContext(paymentContext);

  const {
    data: paygPrice,
    isLoading: fetchingPaygPrice,
    isSuccess: fetchedPaygPrice,
  } = useGetPaygRateQuery({}, { skip: withinPromoPeriod });

  const {
    data: paygPromoPrice,
    // isLoading: fetchingPaygPromoPrice,
    isSuccess: fetchedPaygPromoPrice,
  } = useGetPaygPromoRateQuery({}, { skip: !withinPromoPeriod });

  const {
    data: subscriptionPlans,
    isLoading: fetchingSubScriptionPlans,
    isSuccess: fetchedSubscriptionPlans,
    // isError: fetchSubscriptionPlansError,
  } = useGetPlansQuery();

  useEffect(() => {
    if (fetchedSubscriptionPlans) {
      (async () => {
        dispatch({ type: SET_LOADING, payload: true });
        
        try {
          // Determine user location for currency
          const currLoc = await getUserLocation();
          dispatch({ type: SET_CURRENCY, payload: currLoc === 'NG' ? 'NGN' : 'USD' });
        } catch {
          dispatch({ type: SET_CURRENCY, payload: 'USD' });
        }

        // Dispatch the fetched plans from RTK query
        const userDetails = await me();
        dispatch({
          type: SET_CURRENT_PLAN,
          payload: { ...userDetails.user.userPlan, isPaidUser: userDetails.user.isPaidUser },
        });

        dispatch({ type: SET_PLANS, payload: subscriptionPlans });
        dispatch({ type: SET_LOADING, payload: false });
        setIsLoading(false);
      })();
    }

    //eslint-disable-next-line
  }, [dispatch, fetchingSubScriptionPlans]);

  useEffect(() => {
    if (fetchedPaygPrice) {
      dispatch({
        type: SET_PAYG_RATES,
        payload: { NGN: paygPrice?.data?.paygInNaira, USD: paygPrice?.data?.paygInDollar },
      });
    }

    if (fetchedPaygPromoPrice) {
      dispatch({
        type: SET_PAYG_RATES,
        payload: { NGN: paygPromoPrice?.data?.paygPromoInNaira, USD: paygPromoPrice?.data?.paygPromoInDollar },
      });
    }
  }, [dispatch, fetchedPaygPrice, fetchedPaygPromoPrice, paygPrice, paygPromoPrice]);

  // Handle subscription prompt visibility for subscription plans only
  const [subPrompt, setSubPrompt] = useState(false);

  return (
    <>
    <Helmet>
        <title>MailDrip | Payment</title>
        <meta
          name="description"
          content="Choose a MailDrip plan that grows with your business. Whether you're just starting or scaling up, we've got the perfect email marketing package for you."
        />
      </Helmet>
    <h1 className="SEO-hide" aria-hidden="false">MailDrip App Plans</h1>
    <div className="emmaccen">
      <div className="paymentPageContainer">
        <p className="desc">Select a plan that’s just right for you</p>
        <Divider />

        {loading || isLoading || fetchingPaygPrice ? (
          <LoadingScreen />
        ) : (
          <div className="payment_container">
            <div className="payment_container__grid">
              <div className="payment_container__plans">
                <PlansSection setPrompt={setSubPrompt} />
                <div className="divider_wrapper mx-auto">
                  <Divider>OR</Divider>
                </div>{' '}
                <PAYG />
              </div>
              <CheckoutCard subPrompt={subPrompt} setPrompt={setSubPrompt} />
            </div>
          </div>
        )}
      </div>
    </div>
    </>
  );
}
