import React, { useContext, useEffect } from 'react';
// import Chevron from '../../../asset/Icons/Chevron';
import { ReactComponent as Lock } from '../../../asset/Icons/lock.svg';
import { paymentContext } from '../../../contexts/payment';
import Card from '../Card';
// import Form from '../Form';
import TransactionOTP from '../OTP';
import TransactionPin from '../PIN';
import './style.scss';
import PayButton from '../PayButton';
import {
  SET_CARDS,
  SET_CURRENCY,
  SET_DEFAULT_STATE,
  SET_MAKING_TRANSACTION,
  SET_PRICE,
} from '../../../contexts/payment/reducer/types';
import {
  createPaygTrx,
  createSubTrx,
  createTopupTrx,
  getPaystackCards,
  verifyTrx,
} from '../../../utils/services/paystack';
import { showToastMessage } from '../../../utils/Toast';
import { authorizationStep, growthBookId, trxType } from '../../../utils/constants';
import TransactionPhonenumber from '../PhoneNumber';
import TransactionBirthday from '../Birthday';
import { thousandFormatter } from '../../../utils/thousandFormatter';
import { useNavigate } from 'react-router-dom';
import { PaymentError } from '../PaymentError';
import { MdGlobalButton, MdTypography } from 'components/global';
import {
  useCreatePaygTrxMutation,
  useCreateSubTrxMutation,
  useCreateTopupTrxMutation,
  useUpdateSubscriptionWithPaystackMutation,
} from 'redux/featureApi/paymentApiSlice';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/featureSlices/authSlice';
import { Select } from 'antd';
import StripeCheckout from '../Stripe/StripeCheckout';
import InformationIcon from 'asset/Icons/InformationIcon';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

export default function CheckoutCard({ subPrompt, setPrompt }) {
  return <CheckoutToPaymentHandler prompt={subPrompt} updatePrompt={setPrompt} />;
}

// eslint-disable-next-line no-unused-vars
const TransactionComponents = () => {
  const {
    state: { ongoing_trx_status },
  } = useContext(paymentContext);

  if (ongoing_trx_status === authorizationStep.none) {
    return <PaymentForm />;
  } else if (ongoing_trx_status === authorizationStep.pin) {
    return <TransactionPin />;
  } else if (ongoing_trx_status === authorizationStep.otp) {
    return <TransactionOTP />;
  } else if (ongoing_trx_status === authorizationStep.phone_number) {
    return <TransactionPhonenumber />;
  } else if (ongoing_trx_status === authorizationStep.birthday) {
    return <TransactionBirthday />;
  } else {
    return <PaymentError />;
  }
};

const PaymentForm = () => {
  // const [cardsAccordion, openCardsAccordion] = useState(false);
  // const [formAccordion, openFormAccordion] = useState(true);

  // const toggleAccordions = () => {
  //   openCardsAccordion(!cardsAccordion);
  //   openFormAccordion(!formAccordion);
  // };

  return (
    <CheckoutToPaymentHandler />

    // <PaystackCheckout>
    //   <div>
    //     <h2 className="payment_form__header">
    //       You pay: <span>₦{thousandFormatterFloat(state.price)}</span>
    //     </h2>

    //     <div className="accordion" onClick={() => toggleAccordions()}>
    //       <h6>Saved cards</h6>
    //       <Chevron className={cardsAccordion ? 'chevronFaceUp' : 'chevronFaceDown'} />
    //     </div>
    //     {cardsAccordion && <CardsSection toggleAccordions={toggleAccordions} />}

    //     <div className="accordion new_card__accordion" onClick={() => toggleAccordions()}>
    //       <h6>New Card</h6>
    //       <Chevron className={formAccordion ? 'chevronFaceUp' : 'chevronFaceDown'} />
    //     </div>
    //     {formAccordion && <Form toggleAccordions={toggleAccordions} />}
    //   </div>
    // </PaystackCheckout>
  );
};

export const CheckoutToPaymentHandler = ({ prompt, updatePrompt }) => {
  const { Option } = Select;
  const {
    state: { plan, currentPlan: userCurrentPlan, currency, currencies, quantity, price, payg_rates, topup_rates },
    dispatch,
  } = useContext(paymentContext);

  const userData = useSelector(selectCurrentUser);
  const hideGhanaCedis = useFeatureIsOn(growthBookId.hideGhanaCedis);

  const navigate = useNavigate();

  const [createPaygTrx, { isLoading: requestingPaygRedirectionLink }] = useCreatePaygTrxMutation();

  const [createSubTrx, { isLoading: requestingSubRedirectionLink }] = useCreateSubTrxMutation();

  const [createTopupTrx, { isLoading: requestingTopupRedirectionLink }] = useCreateTopupTrxMutation();

  const [updateSubscriptionWithPaystack, { isLoading: requestingUpdateSubscriptionLink }] =
    useUpdateSubscriptionWithPaystackMutation();

  const redirectToPaymentHandler = async ({ plan, quantity }) => {
    const payload = {
      quantity: plan?.payg ? +quantity : +plan.limit,
      callback_url: `${window.location.origin}/payment/pay?channel=paystack`,
      cancel_url: `${window.location.origin}/payment/pay?trx-cancelled=true`,
    };

    if (plan?.payg) {
      createPaygTrx(payload)
        .unwrap()
        .then((res) => {
          window.location.href = res.data.message.data.authorization_url;
        })
        .catch((err) => {
          showToastMessage({
            type: 'error',
            title: 'Payment Error',
            description: err?.data?.message,
          });
        });
    } else if (plan.topup) {
      const payload = {
        quantity: plan.limit,
        callback_url: `${window.location.origin}/settings/account/plans`,
        cancel_url: `${window.location.origin}/settings/account/plans?trx-cancelled=true`,
      };
      createTopupTrx(payload)
        .unwrap()
        .then((res) => {
          window.location.href = res.data.message.data.authorization_url;
        })
        .catch((err) => {
          showToastMessage({
            type: 'error',
            title: 'Payment Error',
            description: err?.data?.message.includes('have an active subscription')
              ? 'You have an active subscription, kindly Top up instead'
              : err?.data?.message,
          });
        });
    } else {
      payload.planId = plan.paystackPlanId;

      if (!userCurrentPlan?.isPaidUser) {
        createSubTrx(payload)
          .unwrap()
          .then((res) => {
            window.location.href = res.data.message.data.authorization_url;
          })
          .catch((err) => {
            showToastMessage({
              type: 'error',
              title: 'Payment Error',
              description: err?.data?.message.includes('have an active subscription')
                ? 'You have an active subscription, kindly Top up instead'
                : err?.data?.message,
            });
          });
      } else if (userCurrentPlan?.paystackPlanId === plan.paystackPlanId) {
        return showToastMessage({
          type: 'error',
          title: 'Subscription Error',
          description: 'You are already subscribed to this plan, please select another',
        });
      } else {
        updateSubscriptionWithPaystack(payload)
          .unwrap()
          .then((res) => {
            window.location.href = res.data.transaction.data.authorization_url;
          })
          .catch((err) => {
            showToastMessage({
              type: 'error',
              title: 'Payment Error',
              description: err?.data?.message ? err?.data?.message : 'An error occured, Please try again',
            });
          });
      }
    }
  };

  const options = {
    type: 'Pro (Annual)',
    status: 'trialing',
    livemode: true,
    email: userData?.local,
    affiliate_id: userData?.referralId,
  };
  const recordConversionOnLinkMink = async (options) => {
    try {
      const response = await fetch('https://app.linkmink.com/api/v0.1.0/conversions', {
        method: 'POST',
        headers: {
          Authorization: `Bearer priv_live_M6OTaROLLmbRqvx5IHRF`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(options),
      });

      if (response.ok) {
        navigate('/settings/account/plans');
      } else {
        console.log('Failed to record conversion', await response.json());
      }
    } catch (error) {
      console.log({ error });
    }
  };

  // useEffect to always check if the user was redirect back to this page from paystack
  useEffect(() => {
    const url = new URL(window.location.href);
    const ref = url.searchParams.get('reference');
    const trxCancelled = url.searchParams.get('trx-cancelled');
    if (ref) {
      const isSuccessful = VerifyTransaction(ref);
      if (isSuccessful) {
        userData.referralId && recordConversionOnLinkMink(options);
        navigate('/settings/account/plans');
      }
    }
    if (trxCancelled) {
      failedTrx('Transaction was cancelled');
      navigate('/payment/pay');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if(requestingPaygRedirectionLinkFailed || requestingSubgRedirectionLinkFailed) {
  //     showToastMessage({
  //       type: 'error',
  //       title: 'Payment Error',
  //       description: 'Could not redirect to secure payment page. Please try again.',
  //     });
  //   }
  // },[requestingPaygRedirectionLinkFailed, requestingSubgRedirectionLinkFailed]);

  useEffect(() => {
    if (plan.payg) {
      updatePrompt(false);
    }
    // eslint-disable-next-line
  }, [plan.payg]);

  return (
    <div data-test="payment_checkout_container">
      <div className="payment_currency_container">
        <MdTypography variant={'pLight'}>Billed in: </MdTypography>
        <Select
          value={currency}
          style={{ width: 117, border: 'none' }}
          className="payment_currency_select"
          getPopupContainer={() => document.querySelector('.payment_currency_container')}
          onChange={(value) => {
            dispatch({ type: SET_CURRENCY, payload: value });
            if (plan.payg) {
              const newPrice = quantity * payg_rates[value];
              return dispatch({ type: SET_PRICE, payload: newPrice });
            } else if (plan.topup) {
              const newPrice = plan.limit * topup_rates[value];
              return dispatch({ type: SET_PRICE, payload: newPrice });
            } else {
              dispatch({ type: SET_PRICE, payload: currency === 'NGN' ? plan.priceInDollars : plan.priceInNaira });
            }
          }}
          data-test="payment_currency_select"
        >
          <Option value="USD" className="payment_currency_option" data-test="payment_currency_option_usd">
            $ USD
          </Option>
          <Option value="NGN" className="payment_currency_option" data-test="payment_currency_option_ngn">
            ₦ Naira
          </Option>
          {!hideGhanaCedis && (
            <Option value="GHS" className="payment_currency_option" data-test="payment_currency_option_ghs">
              ₵ Cedis
            </Option>
          )}
        </Select>
      </div>
      <div className={'payment_form'}>
        {prompt && (
          <ol className="list-decimal text-red-600 mx-3">
            <li>Your subscription auto renews monthly and you can unsubscribe from the plan at anytime.</li>
            <li>Unused email credits do not roll over into the next month.</li>
          </ol>
        )}
        <div>
          <h2 className="payment_form__header">
            You pay:{' '}
            <span>
              {currencies[currency]}
              {thousandFormatter(parseFloat(price).toFixed(2))}
            </span>
          </h2>
          {plan?.payg && price < 100 && currency === 'NGN' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px',
              }}
            >
              <InformationIcon width="20" />
              <p>Minimum order price is ₦100</p>
            </div>
          )}
          {currency === 'NGN' ? (
            <MdGlobalButton
              size={'large'}
              htmlType="submit"
              className="payment__button"
              loading={
                requestingPaygRedirectionLink ||
                requestingSubRedirectionLink ||
                requestingTopupRedirectionLink ||
                requestingUpdateSubscriptionLink
              }
              disabled={
                requestingPaygRedirectionLink ||
                requestingSubRedirectionLink ||
                requestingTopupRedirectionLink ||
                requestingUpdateSubscriptionLink ||
                plan?.payg
                  ? price < 100
                  : false
              }
              onClick={() => {
                redirectToPaymentHandler({ plan, quantity });
              }}
            >
              Continue
            </MdGlobalButton>
          ) : (
            <div className="stripe-payment-container">
              <StripeCheckout />
            </div>
          )}
        </div>

        <div className="paystack_signature">
          <p>Secured by {currency === 'NGN' ? 'Paystack' : 'Stripe'} </p>
          <Lock />
        </div>
      </div>
    </div>
  );
};

export const PaystackCheckout = ({ children, className = '' }) => {
  return (
    <div className={'payment_form ' + className}>
      {children}
      <div className="paystack_signature">
        <p>Secured by Paystack </p>
        <Lock />
      </div>
    </div>
  );
};

export const CardsSection = ({ toggleAccordions }) => {
  // const [makingTransaction, setMakingTransaction] = useState(true);
  const { state, dispatch } = useContext(paymentContext);
  const navigate = useNavigate();

  const cards = state.cards;
  useEffect(() => {
    (async () => {
      if (!cards.length) {
        const {
          data: { data: cards },
        } = await getPaystackCards();
        dispatch({ type: SET_CARDS, payload: cards });
      }
    })();
  }, [cards.length, dispatch]);
  return (
    <div className="saved_cards">
      {!cards.length ? (
        <div className="empty">
          <p>No card saved yet.</p>
          <p> All saved cards will appear here</p>
        </div>
      ) : (
        <>
          {cards.map((card) => {
            return <Card key={card._id} card={card} />;
          })}

          <PayButton
            loading={state.making_transaction}
            onClick={async () => {
              if (+state.price < 100) {
                return showToastMessage({
                  type: 'error',
                  title: 'Invalid Transaction Amount',
                  description: 'Transaction amount is too small. Minimum amount allowed is NGN100',
                });
              }
              try {
                dispatch({ type: SET_MAKING_TRANSACTION, payload: true });

                let trx;
                if (state.transaction_type === trxType.payg) {
                  // PAYG Transaction
                  const payload = {
                    quantity: state.quantity,
                    authCode: state.card.authorization,
                  };
                  trx = await createPaygTrx(payload);
                } else if (state.transaction_type === trxType.subscription) {
                  const payload = {
                    planId: state.plan.paystackPlanId,
                    authCode: state.card.authorization,
                  };
                  trx = await createSubTrx(payload);
                } else if (state.transaction_type === trxType.topup) {
                  const payload = {
                    quantity: state.plan.limit,
                    amount: state.plan.price,
                    authCode: state.card.authorization,
                  };
                  trx = await createTopupTrx(payload);
                }
                const { transaction } = trx.data.data;
                await VerifyTransaction(transaction.reference);
                // Delay redirect for 2 seconds so that trx will reflect
                setTimeout(() => {
                  dispatch({ type: SET_DEFAULT_STATE });
                  navigate('/settings/account/plans');
                }, 2000);
              } catch (error) {
                console.log({ error });
                dispatch({ type: SET_DEFAULT_STATE });
                toggleAccordions();
                // TODO: Remove error and leave error.message
                failedTrx(error?.message || error);
              }
            }}
          />
        </>
      )}
    </div>
  );
};

export const failedTrx = (message) => {
  showToastMessage({
    type: 'error',
    title: 'Transaction failed',
    description: message,
  });
};

export const VerifyTransaction = async (txref, saveCard = false) => {
  const verifyTrxRes = await verifyTrx(txref, saveCard);
  const isSuccessful = verifyTrxRes.data.data.transaction.status === 'success';
  const isPending = verifyTrxRes.data.data.transaction.status === 'pending';
  const hasFailed = verifyTrxRes.data.data.transaction.status === 'failed';

  if (isSuccessful) {
    showToastMessage({
      type: 'success',
      title: 'Payment Successful',
      description: verifyTrxRes.data.data.message || 'Your payment was successful.',
    });

    return true;
  } else if (isPending) {
    // console.log({ isPending: txref });
    // A very rare occurence according to Paystack but nonetheless needs to be accounted for
    showToastMessage({
      type: 'warning',
      title: 'Verifying Payment',
      description: "Please do not refresh this page. We're processing your payment. This may take up to a minute",
      duration: 15,
    });
    // Re-verify after 10-30 sconds as stated in paystack docs
    setTimeout(async () => await VerifyTransaction(txref, saveCard), 15 * 1000);
  } else if (hasFailed) {
    showToastMessage({
      type: 'error',
      title: 'Payment Unsuccessful',
      description: `${verifyTrxRes.data.data.message}. Please try again.`,
    });
  } else {
    showToastMessage({
      type: 'error',
      title: 'Something went wrong!',
      description: verifyTrxRes.data.data.message,
    });
  }
  return false;
};
