import { Button, Form, Input, Drawer, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useEditContactMutation } from '../../redux/featureApi/contactApiSlice';
import '../../styles/modals.scss';
import { showToastMessage } from '../../utils/Toast';
import { isUserOnFreePlan } from '../../utils/generics';
import { MdTypography } from '../../components/global';
import { MdClose } from './Icons/MdClose';
import { MdArrowDown } from './Icons/MdArrowDown';
import styles from '../../styles/newContactPage.module.scss';
import { MdUserOutline } from './Icons/MdUserOutline';

export const MdEditContact = ({
  imVisible,
  setImVisible,
  actionableContactData,
  contactGroups,
  setMultiselectionData,
}) => {
  const [contactForm] = Form.useForm();
  const { Option } = Select;
  const [user] = useState(JSON.parse(localStorage.getItem('userDetails')));
  const [userIsOnFreePlan] = useState(isUserOnFreePlan(user.user));
  const [valueChanged, setValueChanged] = useState(false);

  //edit contact with rtk
  const [editContact, { isLoading: editingContactData, isSuccess, isError, error }] = useEditContactMutation();

  const newActionableContactData = {
    firstName: actionableContactData?.name
      ? actionableContactData?.name?.split(' ').at(0)
      : actionableContactData?.firstName,
    lastName: actionableContactData?.name
      ? actionableContactData?.name?.split(' ').at(1)
      : actionableContactData?.lastName,
    email: actionableContactData?.email,
    groups: actionableContactData?.groups?.filter((data) => data != null),
    id: actionableContactData?._id,
  };

  const listGroups = (arr1, arr2) => {
    const result = arr1.filter((item) => arr2?.includes(item?._id));
    return result;
  };
  const groups = listGroups(contactGroups, newActionableContactData.groups);

  const onSubmitContactDetail = async ({ firstName = '', lastName = '', groups = [], campaignId = '' }) => {
    await editContact({ contactId: actionableContactData._id, contactData: { firstName, lastName, groups } });
  };
  /* eslint-disable */
  useEffect(() => {
    if (isSuccess) {
      showToastMessage({
        type: 'success',
        title: 'Contact',
        description: 'Contact details updated!',
      });
      setImVisible({ id: '' });
    } else if (isError) {
      console.log(error);
      setImVisible({ id: '' });
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (imVisible.id) contactForm.setFieldsValue({ newActionableContactData });
    else contactForm.resetFields();
  }, [imVisible]);

  return (
    <Drawer
      placement={'right'}
      className={`emmaccen`}
      closable={false}
      onClose={() => setImVisible({ id: '' })}
      open={imVisible.id === 'editContact'}
      destroyOnClose={true}
    >
      <div className={styles['addNewContactModal']}>
        <div className="contactDrawer mb-8">
          <div className="flex justify-end">
            <div className="close-icon" onClick={() => setImVisible({ id: '' })}>
              <MdClose />
            </div>
          </div>
          <div className="flex justify-between p-3 items-end creator">
            <div className="flex gap-4">
              <div className="name-box">
                {`${newActionableContactData?.firstName?.charAt(0)}${
                  newActionableContactData?.lastName?.charAt(0) || ''
                }`}
              </div>
              <div className="block">
                <div className="creator-name">
                  {`${newActionableContactData?.firstName} ${newActionableContactData?.lastName || ''}`}
                </div>
                <div className="name-tag">{groups?.at(0)?.title || 'All contacts'}</div>
              </div>
            </div>
          </div>
        </div>

        <Form
          layout="vertical"
          form={contactForm}
          initialValues={{ ...newActionableContactData }}
          onFinish={(data) => onSubmitContactDetail(data)}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
          onFieldsChange={(changedValue, allFields) => {
            const hasChanges = changedValue.some((value) => {
              const fieldName = value.name[0];
              const initialValue = newActionableContactData[fieldName];
              return initialValue !== value.value;
            });
            if (hasChanges) {
              setValueChanged(true);
            } else {
              setValueChanged(false);
            }
          }}
        >
          <Form.Item label="First name" name="firstName">
            <Input placeholder="John Doe" prefix={<MdUserOutline />} />
          </Form.Item>
          <Form.Item label="Last name" name="lastName">
            <Input placeholder="John Doe" prefix={<MdUserOutline />} />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please, provide an email!',
                type: 'email',
              },
            ]}
          >
            <Input
              type="email"
              placeholder="johndoe@person.com"
              prefix={<MdUserOutline />}
              disabled={userIsOnFreePlan}
            />
          </Form.Item>

          <Form.Item label="Add to groups (optional)" name="groups">
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Add to groups (optional)"
              optionLabelProp="label"
              suffixIcon={<MdArrowDown />}
              filterOption={(input, option) => {
                return option.label.toLowerCase().includes(input.trim().toLowerCase());
              }}
            >
              {contactGroups?.map((data) => {
                return (
                  <Option key={data._id ?? data.title} value={data._id} label={data.title} data-test="edit-contact-group-option">
                    <div className="demo-option-label-item">{data.title}</div>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <div className={`${styles['additional-info']} my-10`}>
            <div className={`${styles['info-text']}  mb-6 uppercase`}>Additional information</div>
            <div className="flex gap-12">
              <div className={styles['info-text']}>Groups</div>
              <div className={styles['info-item']}>{groups?.map((x) => x.title).join(', ')}</div>
            </div>
          </div>

          <Button
            size={'large'}
            htmlType="submit"
            className={`${styles['contact-btn']} w-full`}
            loading={editingContactData}
            disabled={!valueChanged}
          >
            Save changes
          </Button>
        </Form>
        <MdTypography
          variant={'btnRegular'}
          className={styles['delete-text']}
          onClick={() => {
            setImVisible({ id: 'deleteContact' });
            setMultiselectionData([newActionableContactData?.id]);
          }}
        >
          Delete Contact
        </MdTypography>
      </div>
    </Drawer>
  );
};
