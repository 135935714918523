import React from 'react';

export default function Cancels({ height, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="-5.0 -10.0 110.0 135.0"
      height={height}
      width={width}
    >
      <path d="m27.461 22.5c-1.2656 0-2.5352 0.48438-3.5039 1.457-1.9414 1.9414-1.9414 5.0703 0 7.0117l19.031 19.031-19.031 19.035c-1.9414 1.9414-1.9414 5.0664 0 7.0078 1.9414 1.9414 5.0703 1.9414 7.0117 0l19.031-19.031 19.031 19.031c1.9414 1.9414 5.0703 1.9414 7.0117 0 1.9414-1.9414 1.9414-5.0664 0-7.0078l-19.031-19.035 19.031-19.031c1.9414-1.9414 1.9414-5.0703 0-7.0117-1.9414-1.9414-5.0703-1.9414-7.0117 0l-19.031 19.031-19.031-19.031c-0.97266-0.97266-2.2383-1.457-3.5039-1.457z" />
    </svg>
  );
}
