import React, { useEffect, useContext } from 'react';
import { Modal, Input, Button, Form, message } from 'antd';
import PropTypes from 'prop-types';
import { mustBeValidUrl } from '../../../utils/formRules';
import { joinCampaignContext } from 'contexts/joincampaign';
import { getPublishPageRequest, postPublishPageRequest } from 'utils/service';
import { showToastMessage } from 'utils/Toast';
import { SET_JOIN_CAMPAIGN_FIELDS, SET_LINK } from 'contexts/joincampaign/reducer/types';
import { useState } from 'react';

const RedirectUrlInput = ({
  setEmbeddedModal = () => {},
  imVisible,
  setImVisible,
  credencials = {},
  campaignId,
  context,
  data,
  campaignTitle,
}) => {
  const [redirectUrlForm] = Form.useForm();

  const { state, dispatch } = useContext(joinCampaignContext);
  const [loading, setLoading] = useState(false);

  const {
    btnText,
    alttext,
    sectionbackground,
    tagline,
    catchphrase,
    btnColor,
    displayName,
    displayLastName,
    displayCompanyName,
    displayPhoneNumber,
    setLogoFiles,
    displayEmail,
    formFields,
    setLink,
  } = state;

  useEffect(() => {
    getJoinPageValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getJoinPageValues = () => {
    getPublishPageRequest(campaignId)
      .then(({ data: { data } }) => {
        const payload = {
          tagline: data.tagline || 'Join Campaign',
          btnText: data.button.text || 'Join',
          catchphrase: data.headline || 'Some description or catch phrase goes in here',
          alttext: data.alttext,
          sectionbackground: data.background_color || '#ffffff',
          btnColor: data.button.background_color,
          logo: data.logo_url,
          formFields: data.form_fields || [],
          setLink: data.success_redirect_url,
        };

        dispatch({ type: SET_JOIN_CAMPAIGN_FIELDS, payload });
      })
      .catch((error) => {
        console.log({ error });
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'Sorry, an error occured',
        });
      });
  };

  const savedCampaignURLs = JSON.parse(localStorage.getItem('campaignURLs')) ?? [];
  const getCampaignUrl = savedCampaignURLs.find((url) => url.id === campaignId);

  const handleCancel = () => {
    setImVisible(false);
  };

  const { user } = JSON.parse(localStorage.getItem('userDetails'));
  const userName = user.username;

  const handleSubmission = ({ redirect_url }) => {
    const urlRegex = /(https?:\/\/)(www\.)?[a-zA-Z]+\.([a-zA-Z]{2,})(\/[a-zA-Z0-9-_]*)?/;

    if (urlRegex.test(redirect_url) === false) {
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Please enter a valid url',
      });

      return;
    }
    const reconstructUrl = (url) => {
      if (url && !/^https?:\/\/(www\.)?/.test(url)) {
        return `https://www.${url}`;
      } else {
        return url;
      }
    };
    const modifiedUrl = reconstructUrl(redirect_url);

    setLoading(true);
    const prevCampaignURLs = savedCampaignURLs.filter((url) => url.id !== campaignId);
    const updateCampaignURLs = [
      ...prevCampaignURLs,
      {
        id: campaignId,
        url: modifiedUrl,
      },
    ];

    const payload = {
      button_text: btnText,
      alttext,
      background_color: sectionbackground,
      tagline,
      headline: catchphrase,
      button_bg_color: btnColor,
      displayName,
      displayLastName,
      displayEmail,
      displayPhoneNumber,
      displayCompanyName,
      logo: setLogoFiles[0],
      form_fields: formFields,
      success_redirect_url: modifiedUrl,
    };

    localStorage.setItem('campaignURLs', JSON.stringify(updateCampaignURLs));

    const STEP2_COPY_REDIRECT = `
  <form id="maildrip-contact-form" data-maildrip-apikey="${credencials?.apiKey}" data-maildrip-redirect-url="${modifiedUrl}"  data-maildrip-accesssecret="${credencials?.secretKey}" data-maildrip-campaignid="${campaignId}">
    <input type="text" class="maildrip-newsub-name" placeholder="Your Name" />
    <input type="email" class="maildrip-newsub-email" placeholder="Your Email" />
    <input type="hidden" name="urlRedirect" class="maildrip-newsub-url" value="${modifiedUrl}" />
    <button type="submit">Submit</button>
  </form>
    `;

    postPublishPageRequest(payload, campaignId)
      .then((res) => {
        dispatch({ type: SET_LINK, payload: modifiedUrl });
        // showToastMessage({
        //   type: 'success',
        //   title: 'Published',
        //   description: 'Your page has been published',
        // });
        const JOIN_CAMPAIGN_REDIRECT = `${window.location.origin}/${userName}/join/${campaignTitle}?id=${campaignId}`;
        switch (context) {
          case 'htmlForm':
            navigator.clipboard.writeText(STEP2_COPY_REDIRECT);
            message.success('Form tag with redirect link copied successfully');
            break;
          case 'joinPage':
            navigator.clipboard.writeText(JOIN_CAMPAIGN_REDIRECT);
            message.success('Link copied successfully');
            break;

          default:
            break;
        }
        setLoading(false);
        setImVisible(false);
      })
      .catch((error) => {
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: 'An error occured',
        });
      });

    setEmbeddedModal({ id: '' });
  };

  return (
    <Modal
      className="emmaccen"
      open={imVisible}
      afterClose={() => {
        // setImVisible(false);
      }}
      footer={null}
      maskClosable={false}
      closable={false}
      centered={true}
      destroyOnClose={true}
    >
      <div className="confirmCsvUpload">
        <Form
          form={redirectUrlForm}
          onFinish={handleSubmission}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
          layout="vertical"
          initialValues={{
            redirect_url: setLink ? setLink : getCampaignUrl ? getCampaignUrl.url : 'https://',
          }}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Sorry, you can not submit empty field!',
              },
              ...mustBeValidUrl,
            ]}
            name="redirect_url"
            label="Redirect URL"
          >
            <Input
              autoFocus
              data-test="redirect-url-input"
              type="text"
              placeholder="Type in the link to redirect users to"
            />
          </Form.Item>
          <div className="emmaccen">
            <div className="flex-space-btw actionBtns">
              <Button size={'large'} type="" onClick={handleCancel}>
                Back
              </Button>
              <Button
                data-test="redirect-url-save-button"
                size={'large'}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

RedirectUrlInput.propTypes = {
  credencials: PropTypes.object,
  context: PropTypes.oneOf(['joinPage', 'htmlForm']).isRequired,
  campaignId: PropTypes.string.isRequired,
  imVisible: PropTypes.bool.isRequired,
  setImVisible: PropTypes.func.isRequired,
  setEmbeddedModal: PropTypes.func,
};

export default RedirectUrlInput;
