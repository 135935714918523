import { Checkbox, Divider, Form, Input } from 'antd';
import { MdGlobalButton, MdTypography } from '../../components/global';
import MdGlobalCTA from '../../components/global/MdGlobalCTA/MdGlobalCTA';
import Cookie from 'js-cookie';
import React, { useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../styles/loginPage.scss';
import { AppConstants } from '../../utils/appConstants';
import { resendEmailVerificationRequest } from '../../utils/service';
import { login, logUserIn, reactivateAccount } from '../../utils/services/authService';
import { showToastMessage } from '../../utils/Toast';
import GoogleAuthButton from './GoogleAuthButton';
import { jobContext } from 'contexts/job';
import { SET_JOBS } from 'contexts/job/reducer/types';
import { Helmet } from 'react-helmet-async';

export const MdLogin = () => {
  const [loading, setLoading] = useState(false);
  const [loginForm] = Form.useForm();
  const { dispatch: jobsDispatch } = useContext(jobContext);

  const navigate = useNavigate();
  const location = useLocation();

  const onFinish = (values) => {
    setLoading(true);
    login(values)
      .then(async ({ data: { success, verified, showModal, token } }) => {
        if (success && verified) {
          await logUserIn(token, navigate, location.state);
          if (JSON.parse(localStorage.getItem('previousJobs'))?.length > 0) {
            jobsDispatch({
              type: SET_JOBS,
              payload: JSON.parse(localStorage.getItem('previousJobs')),
            });
          }
          Cookie.set('firstTimeLog', showModal);
        } else if (success && !verified) {
          resendEmailVerificationRequest(values);
          navigate('/verify', {
            state: {
              email: values.email,
              resendVerificationMail: true,
            },
          });
        }
      })
      .catch((error) => {
        (async () => {
          const err = error?.response?.data;
          console.log({ err });
          if (err?.error_code === 'account_deactivated') {
            try {
              await reactivateAccount(err.email, navigate);
            } catch (error) {
              console.log({ error });
            }
          } else {
            showToastMessage({
              type: 'error',
              title: 'Error',
              description: err?.message || err?.error || 'Sorry, an error occured 😔',
            });
          }
          setLoading(false);
        })();
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // export const redirectToBlog = () => {
  //   window.open('https://maildrip.io', '_blank');
  // };

  return (
    <>
      <Helmet>
        <title>MailDrip | Login</title>
        <meta
          name="description"
          content="Welcome back to MailDrip! Access your email campaigns, check your analytics, and continue growing your audience. Your success awaits."
        />
      </Helmet>
      <h1 className="SEO-hide" aria-hidden="false">MailDrip App Login</h1>
      <div className="emmaccen">
        <div className="loginPageContainer">
          <div className="loginSections flex">
            <MdGlobalCTA />
            <div className="w-50 formArea">
              <div className="formContent">
                <div className="container2">
                  <a
                    href={AppConstants.MAILDRIP_BLOG_URL}
                    target="_blank"
                    rel="noreferrer"
                    className="flex-jst-right pt-2 md-logo"
                  >
                    <img src="/assets/smallLogo.svg" alt="company logo" className="mb-5" />
                  </a>

                  <div className="margin-t-b centered">
                    <MdTypography variant={'h1'} className="title mb-2">
                      Welcome back
                    </MdTypography>
                  </div>
                  <div className="centered margin-t-b l-up-wg-lg">
                    <GoogleAuthButton>
                      <MdTypography variant={'h6Medium'} className="googleText">
                        Sign in with Google
                      </MdTypography>
                    </GoogleAuthButton>
                  </div>
                  <div className="margin-t-b orDivider">
                    <Divider plain>
                      <MdTypography variant={'smallTextMedium'}>Or</MdTypography>
                    </Divider>
                  </div>
                  <Form
                    name="basic"
                    form={loginForm}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size="large"
                    layout="vertical"
                  >
                    <Form.Item
                      label="Your email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your email!',
                          type: 'email',
                        },
                      ]}
                    >
                      <Input placeholder="Email" data-test="login-email" />
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]}
                      className="password-div"
                    >
                      <Input.Password placeholder="Password" data-test="login-pwd" />
                    </Form.Item>

                    <div className="forgot-password-remember-me">
                      <Link to="/forgot-password">
                        <MdTypography variant={'btnRegular'} className="mdForgtPwd">
                          Forgot password?
                        </MdTypography>
                      </Link>

                      <Form.Item name="remember" valuePropName="checked">
                        <Checkbox className="remember-me">Remember me</Checkbox>
                      </Form.Item>
                    </div>

                    <Form.Item>
                      <div className="centered">
                        <MdGlobalButton
                          dataTestId="login-btn"
                          id={'primary_xl'}
                          htmltype="submit"
                          loading={loading}
                          style={{ width: '100%' }}
                        >
                          <MdTypography variant={'h6Medium'} style={{ color: '#ffffff' }}>
                            Log In
                          </MdTypography>
                        </MdGlobalButton>
                      </div>
                    </Form.Item>

                    <div className="centered margin-t-b l-up-wg-mobile">
                      <GoogleAuthButton>
                        <MdTypography variant={'h6Medium'} className="googleText">
                          Sign in with Google
                        </MdTypography>
                      </GoogleAuthButton>
                    </div>
                  </Form>
                  {/* <Link to="/"> */}
                  <MdTypography variant={'h6Medium'} className="text-center signUpQ" style={{ color: '#858A8F' }}>
                    Don't have an account?
                    <Link to="/signup" className="primaryColorLight ml-2">
                      Sign Up
                    </Link>
                  </MdTypography>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
