import { Button, Form, Input, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePostNewContactToGroupMutation } from '../../redux/featureApi/contactApiSlice';
import '../../styles/modals.scss';
import { showToastMessage } from '../../utils/Toast';
// import { doNotAllowSpacesAndOtherCharacter } from '../../utils/formRules';
import { errorMessage } from '../../utils/getErrors';
import { MdTypography } from 'components/global';
import { MdArrowDown } from './Icons/MdArrowDown';
import styles from '../../styles/newContactPage.module.scss';
import { MdClose } from './Icons/MdClose';

export const MdCreateNewContact = ({ imVisible, setImVisible, contactGroups, currentGroup, setCsvResult, refetch }) => {
  // eslint-disable-next-line no-unused-vars
  const [loading, setImLoading] = useState({ id: '' });
  const { payload, groupId } = useParams();
  const [contactForm] = Form.useForm();

  const { Option } = Select;

  //add new contact to group with rtk
  const [addNewContactToGroup, { isLoading: postingNewContactToGroup }] = usePostNewContactToGroupMutation(
    payload,
    groupId
  );

  const onSubmitContactDetail = async (contact) => {
    try {
      await addNewContactToGroup({
        payload: contact,
        groupId: currentGroup._id,
      })
        .unwrap()
        .then(() => {
          refetch();
          showToastMessage({
            type: 'success',
            title: 'Contact Added',
            description: 'Contact added successfully',
          });
          setImLoading({ id: '' });
          setImVisible({ id: '' });
        });
    } catch (error) {
      const errMsg = errorMessage(error);
      showToastMessage({
        type: 'error',
        title: 'Error',
        description: errMsg,
      });
      setImLoading({ id: '' });
      setImVisible({ id: '' });
    }
  };
  /* eslint-disable */
  useEffect(() => {
    contactForm.resetFields();
    setCsvResult([]);
  }, [imVisible]);

  // const validator = (_, value) => {
  //   if (value && /\s/.test(value)) {
  //     return Promise.reject('Spaces are not allowed');
  //   }
  //   return Promise.resolve();
  // };

  return (
    <Modal
      className={`emmaccen ${styles['contact-modal']}`}
      open={imVisible.id === 'addNewContactGroup'}
      afterClose={() => {}}
      footer={null}
      maskClosable={false}
      onCancel={() => setImVisible({ id: '' })}
      closable={true}
      destroyOnClose={true}
      closeIcon={<MdClose />}
      centered
    >
      <div className={styles['addNewContactModal']}>
        <MdTypography variant={'h5Medium'} className="text-center title mb-8">
          New Contact
        </MdTypography>
        <Form
          form={contactForm}
          onFinish={onSubmitContactDetail}
          onFinishFailed={() => null}
          autoComplete="off"
          size="large"
          layout={'vertical'}
          requiredMark={false}
        >
          <Form.Item
            label="First name"
            name="firstName"
            // rules={[
            //   {
            //     // required: true,
            //     ...doNotAllowSpacesAndOtherCharacter[0],
            //   },
            // ]}
          >
            <Input placeholder="John" data-test="add-contact-firstName" />
          </Form.Item>
          <Form.Item
            label="Last name"
            name="lastName"
            // rules={[
            //   {
            //     // required: true,
            //     ...doNotAllowSpacesAndOtherCharacter[0],
            //   },
            // ]}
          >
            <Input placeholder="Doe" data-test="add-contact-lastName" />
          </Form.Item>

          <Form.Item
            label="Email address"
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Please enter a valid email address',
              },
            ]}
            data-test="email-input"
          >
            <Input type="email" placeholder="johndoe@person.com" data-test="add-contact-email" />
          </Form.Item>
          <Form.Item label="Add to groups(optional)" name="groups" className="mb-0">
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Select Group"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              optionLabelProp="label"
              suffixIcon={<MdArrowDown />}
              data-test="add-contact-selectGroup"
            >
              {contactGroups?.map((data) => {
                return (
                  <Option key={data._id ?? data.title} value={data._id} label={data.title}>
                    <div className="demo-option-label-item">{data.title}</div>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <div className={styles['info-box']}>
            All of your contacts, including those not added to any group, are visible under “All contacts”
          </div>

          <div className="emmaccen">
            <div className="flex-space-btw actionBtns">
              <Button
                size={'large'}
                type="primary"
                htmlType="submit"
                className={`${styles['contact-btn']} w-full  mt-0`}
                loading={postingNewContactToGroup}
                data-test="contact-done"
              >
                Done
              </Button>
            </div>
            <div className={`${styles['uploadContact']} mt-6 mb-6 flex items-center justify-between`}>
              <MdTypography variant={'btnRegular'}>Upload multiple contact?</MdTypography>
              <Button
                className={styles['import-btn']}
                size={'large'}
                type="ghost"
                onClick={() => setImVisible({ id: 'confirmCsvUpload' })}
                data-test="import-contact"
              >
                Import
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
