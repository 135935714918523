import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './style.scss';
import { isUserOnFreePlan } from '../../../utils/generics';
import { getUserPastTransactions, me } from '../../../utils/service';
import LoadingScreen from '../../LoadingScreen';
import TopupModal from '../TopupModal/index';
import TransactionsTable from '../TransactionsTable';
import { thousandFormatter, thousandFormatterFloat } from '../../../utils/thousandFormatter';
import { VerifyTransaction, failedTrx } from '../CheckoutCard';
import { useNavigate } from 'react-router-dom';
import { paymentContext } from 'contexts/payment';
import { SET_CURRENCY } from 'contexts/payment/reducer/types';
import { getUserLocation } from 'utils/services/authService';

export default function Billings() {
  const [userData, setUserData] = useState(null);

  const userPlanName = userData?.userPlan?.planType?.toUpperCase() || 'FREE';
  const [isFreePlan, setIsFreePlan] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openTopupModal, setOpenTopupModal] = useState(false);
  const [wallet, setWallet] = useState({});
  const {
    state: { loading },
    dispatch,
  } = useContext(paymentContext);

  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      try {
        // Attempt to get the user's location
        const currLoc = await getUserLocation();
        if (currLoc === 'NG') {
          dispatch({ type: SET_CURRENCY, payload: 'NGN' });
        } else {
          dispatch({ type: SET_CURRENCY, payload: 'USD' });
        }
      } catch (error) {
        // If getUserLocation fails, default to USD
        // console.error('Error fetching user location:', error);
        dispatch({ type: SET_CURRENCY, payload: 'USD' });
      }

      // Fetch user past transactions
      const response = await getUserPastTransactions();
      setTransactions(response.data.data);

      // Fetch user data
      const userData = await me();
      setUserData(userData.user);

      // Check if the user is on a free plan
      setIsFreePlan(isUserOnFreePlan(userData.user));

      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData?.wallet) setWallet(userData?.wallet);
  }, [userData]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const ref = url.searchParams.get('reference');
    const trxCancelled = url.searchParams.get('trx-cancelled');
    if (ref) {
      const isSuccessful = VerifyTransaction(ref);

      if (isSuccessful) navigate('/settings/account/plans');
    }
    if (trxCancelled) {
      failedTrx('Transaction was cancelled');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props = { openModal: openTopupModal, setOpenModal: setOpenTopupModal, setUserData, setTransactions };
  return (
    <>
      {loading || isLoading ? (
        <LoadingScreen loaderTitle={'Please wait while we perform our magic'} />
      ) : (
        <div className="plansContainer">
          <div className="topupUserPlanWrapper">
            <div className="topupWrapper">
              <h6>Available Unit</h6>

              <div className="availableUnits">
                <p>
                  {thousandFormatter(wallet.subBalance ?? 0)}
                  <span className="totalUnits">/{thousandFormatter(wallet.subTotalSlots ?? 0)} units</span>
                </p>
              </div>
              <div className="topupCtaWrapper">
                <div className="paygBalance">
                  <h6>PAYG Balance</h6>

                  <p>
                    {thousandFormatter(wallet.paygBalance ?? 0)}
                    <span className="totalUnits">/{thousandFormatter(wallet.paygTotalSlots ?? 0)} units</span>
                  </p>
                </div>
                {!isFreePlan && <button onClick={() => setOpenTopupModal(true)}>Top-Up</button>}
              </div>
            </div>

            <div className="planWrapper">
              <div className="priceCtaWrapper">
                <div className={`planType ${isFreePlan ? 'free_plan_background' : 'paid_plan_background'}`}>
                  {userPlanName}
                </div>
                <div className="priceWrapper">
                  <div className="priceTag">
                    {/* <span className="currency">$</span> */}
                    <span className="currency">{userData?.payment?.activePaymentGateway === 0 ? '₦' : '$'}</span>
                    <p>
                      {thousandFormatterFloat(
                        userData?.userPlan
                          ? userData?.payment?.activePaymentGateway === 0
                            ? userData?.userPlan?.priceInNaira
                            : userData?.userPlan?.priceInDollars
                          : 0.0
                      )}
                    </p>
                  </div>
                  <div className="cycle">/month</div>
                </div>
              </div>
              <div className="nextPaymentUpdateCtaWrapper">
                <div className="nextPayment">
                  <h6 className="">Next Payment</h6>
                  {/* Use a more specific check that'll be error free */}
                  <p className="expiryDate">
                    on{' '}
                    {isFreePlan
                      ? '-- -- ----'
                      : moment(userData?.payment?.subscription?.expiresAt).format('MMMM DD, YYYY')}
                  </p>
                </div>
                <Link to={'/payment/pay'}>
                  <button>Update</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="billingHistoryTable">
            <h5>Billing History</h5>

            <TransactionsTable loading={loading} transactions={transactions} />
          </div>

          <div className="psa">
            <p>NOTE: We only show up to 1 year of billing history</p>
          </div>

          {openTopupModal && <TopupModal props={props} />}
        </div>
      )}
    </>
  );
}
