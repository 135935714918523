import { UndoRedoIcon } from 'asset/Icons/UndoRedoIcon';
import { MdGlobalButton, MdTypography } from 'components/global';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadAttachment from 'pages/grapeEditor/LoadAttachment';
import { MdCode } from 'pages/ContactPage/Icons/MdCode';
import { MdPreview } from 'pages/ContactPage/Icons/MdPreview';
import { MdAttach } from 'pages/ContactPage/Icons/MdAttach';
import { MdSend } from 'pages/ContactPage/Icons/MdSend';
import { ChangeTemplateModal } from './ChangeTemplateModal';
import { SaveTemplateModal } from './SaveTemplateModal';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { growthBookId } from 'utils/constants';

export const EditorTopBarCtas = ({
  campaignId,
  mailId,
  landingPageId,
  files,
  setFiles,
  disableTopBtn,
  handleTemplateChange,
  handlePreviewBtnClick,
  isSendingTestMail,
  isSavingMail,
  handleSaveEmailToDB,
  handleSendTestEmail,
  isEditorLoaded,
  isButtonDisabled,
  deleteFile,
  imLoading,
  setCurrentTemplate,
}) => {
  const [attachFile, setAttachFile] = useState(false);
  const [modalvisible, setModalVisible] = useState({ id: '' });
  const [showSaveToTemplateModal, setShowSaveToTemplateModal] = useState(false);

  const disableEmail = useFeatureIsOn(growthBookId.disableEmail);

  const codeEditoreRef = useRef();
  const { search } = useLocation();
  const navigate = useNavigate();
  const keyToPerform = new URLSearchParams(search).get('key');

  useEffect(() => {
    if (keyToPerform === 'upload-code' && isEditorLoaded) {
      setTimeout(() => {
        codeEditoreRef.current?.click();
      }, 4000);
    }
  }, [isEditorLoaded, keyToPerform]);

  const saveAndAskToSave = async () => {
    setShowSaveToTemplateModal(true);
  };

  return (
    <div className={`speditor_builder__top_bar_cta ${disableEmail ? 'pt-16 pb-8' : 'py-8'}`}>
      <div className="speditor_builder__top_bar_cta__ltr">
        <MdGlobalButton
          title={'Exit builder'}
          id={'error_default'}
          className="actionBtn red"
          onClick={() =>
            navigate(
              campaignId
                ? `/campaign/${campaignId}/emails`
                : landingPageId
                ? `/landing-pages`
                : `/instant-emails/drafts/${mailId}`
            )
          }
        />

        {/* <Dropdown menu={{ items }} trigger={['click']} disabled={disableTopBtn}> */}
        <MdGlobalButton
          disabled={disableTopBtn}
          onClick={() => setModalVisible({ id: 'changeTemplate' })}
          title={'Change template'}
          id={'grey_default2'}
          className="actionBtn"
        />
        {/* </Dropdown> */}

        <div className="undoredo_cta">
          <button id="undoButton">
            <UndoRedoIcon type={'undo'} />
          </button>

          <button id="redoButton">
            <UndoRedoIcon type={'redo'} />
          </button>
        </div>
      </div>

      <div className="speditor_builder__top_bar_cta__md">
        <span id="codeEditor" ref={codeEditoreRef}>
          <MdGlobalButton disabled={disableTopBtn} className="actionBtn" id={'grey_default2'}>
            <MdCode />
            <MdTypography>Code Editor</MdTypography>
          </MdGlobalButton>
        </span>

        <span id="previewButton">
          <MdGlobalButton
            disabled={disableTopBtn || isButtonDisabled}
            onClick={handlePreviewBtnClick}
            id={'grey_default2'}
            className="actionBtn"
          >
            <MdPreview />
            <MdTypography>Preview</MdTypography>
          </MdGlobalButton>
        </span>

        {!landingPageId && (
          <MdGlobalButton
            disabled={disableTopBtn}
            id={'grey_default2'}
            className="actionBtn nb"
            onClick={() => setAttachFile(true)}
          >
            <MdAttach />
            <MdTypography>Add an attachment</MdTypography>
          </MdGlobalButton>
        )}
      </div>

      <div className="speditor_builder__top_bar_cta__rtl">
        {!landingPageId && (
          <MdGlobalButton
            disabled={disableTopBtn}
            loading={isSendingTestMail}
            id={'outline_default'}
            className="actionBtn blue"
            onClick={handleSendTestEmail}
          >
            <MdSend />
            <MdTypography>Send as test email</MdTypography>
          </MdGlobalButton>
        )}

        <MdGlobalButton
          disabled={disableTopBtn}
          loading={isSavingMail}
          id={'primary_default'}
          onClick={saveAndAskToSave}
          className="actionBtn solid"
          dataTestId='save-and-continue-button'
        >
          <MdSend color="white" />
          <MdTypography>Save and continue</MdTypography>
        </MdGlobalButton>
      </div>

      <LoadAttachment
        open={attachFile}
        onOk={() => setAttachFile(false)}
        onCancel={() => setAttachFile(false)}
        files={files}
        setFiles={setFiles}
        mailId={mailId}
        deleteFile={deleteFile}
        imLoading={imLoading}
      />
      {modalvisible && (
        <ChangeTemplateModal
          modalVisible={modalvisible}
          setModalVisible={setModalVisible}
          handleTemplateChange={handleTemplateChange}
          setCurrentTemplate={setCurrentTemplate}
          landingPageId={landingPageId}
        />
      )}
      {showSaveToTemplateModal && (
        <SaveTemplateModal
          imVisible={showSaveToTemplateModal}
          setImVisible={setShowSaveToTemplateModal}
          mailId={mailId}
          campaignId={campaignId}
          landingPageId={landingPageId}
          handleSaveEmailToDB={handleSaveEmailToDB}
        />
      )}
    </div>
  );
};
