import { Popconfirm } from 'antd';
import { trunacteString } from '../../../utils/generics';
import moment from 'moment';
import copyIcon from '../../../asset/Icons/copyIcon.png';
import { Link } from 'react-router-dom';
import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import RedirectUrlInput from '../modals/RedirectUrlInput';
import withPopconfirm from '../../../HOC/withPopconfirm';
import { MdTypography } from '../../../components/global';
import Xlogo from 'asset/Icons/Xlogo';
import { cleanString } from 'utils/generics';

const MdCampaignInformation = (props) => {
  const webUrl = window.location.origin;

  const { user } = JSON.parse(localStorage.getItem('userDetails'));
  const campaignId = props.data._id;


  const userName = cleanString(user.username, '-');
  const campaignTitle = cleanString(props.data.name, '-');

  return (
    <div>
      <div className="campaign-information">
        <div className="flex w-full">
          <div className="reporting_sect">
            <MdTypography variant={'h4Light'} className="embeddedTextTitle">
              Reporting
            </MdTypography>
            <div className=" grid-3">
              <div className="flex flex-col g-items">
                <MdTypography variant={'smallTextRegular'}>Recipients</MdTypography>
                <MdTypography variant={'h2'} className="title2Color" dataTestId="analytics-recipients">
                  {props?.campaigns?.analytics?.recipients ?? '0'}
                </MdTypography>
              </div>
              <div className="flex flex-col g-items">
                <MdTypography variant={'smallTextRegular'}>Emails</MdTypography>
                <MdTypography variant={'h2'} className="title2Color" dataTestId="analytics-emails">
                  {props.campaigns?.analytics?.emails ?? '0'}
                </MdTypography>
              </div>
              <div className="flex flex-col g-items">
                <MdTypography variant={'smallTextRegular'}>
                  Conversion Rate <img key="copy-icon" src="/assets/more-info.svg" alt="..." />
                </MdTypography>
                <MdTypography variant={'h2'} className="title2Color" dataTestId="analytics-conversionRate">
                  {props.campaigns?.analytics?.conversionRate ?? 0}%
                </MdTypography>
              </div>
            </div>
            <div className=" grid-3">
              <div className="flex flex-col g-items">
                <MdTypography variant={'smallTextRegular'}>
                  Open Rate <img key="copy-icon" src="/assets/more-info.svg" alt="..." />
                </MdTypography>
                <MdTypography variant={'h2'} className="title2Color" dataTestId="analytics-openRate">
                  {props.campaigns?.analytics?.openRate ?? 0}%
                </MdTypography>
              </div>
              <div className="flex flex-col g-items">
                <MdTypography variant={'smallTextRegular'}>Spam</MdTypography>
                <MdTypography variant={'h2'} className="title2Color" dataTestId="analytics-spams">
                  {props.campaigns?.analytics?.spams ?? '0'}
                </MdTypography>
              </div>
              <div className="flex flex-col g-items">
                <MdTypography variant={'smallTextRegular'}>Unsubscribe</MdTypography>
                <MdTypography variant={'h2'} className="title2Color" dataTestId="analytics-unsubscribes">
                  {props.campaigns?.analytics?.unsubscribes ?? '0'}
                </MdTypography>
              </div>
            </div>
          </div>
          <div className="information_sect">
            <MdTypography variant={'h4Light'} className="embeddedTextTitle">
              Information
            </MdTypography>
            <div className="flex grid-2">
              <div className="flex flex-col g-items">
                <MdTypography variant={'smallTextRegular'} className="embeddedText">
                  Campaign Title
                </MdTypography>
                <MdTypography variant={'pMedium'} className="title2Color" title={props.campaigns?.name}>
                  {trunacteString(props.campaigns?.name, 17)}
                </MdTypography>
              </div>
              <div className="flex grid-2">
                <div className="flex flex-col g-items">
                  <MdTypography variant={'smallTextRegular'} className="embeddedText">
                    Date Created
                  </MdTypography>
                  <MdTypography variant={'pMedium'} className="title2Color">
                    {moment.utc(props.campaigns?.createdAt).format('MMMM Do YYYY')}
                  </MdTypography>
                </div>
              </div>
            </div>
            <div className="flex grid-2">
              <div className="flex flex-col g-items">
                <MdTypography variant={'smallTextRegular'} className="embeddedText">
                  From
                </MdTypography>
                <MdTypography
                  variant={'pMedium'}
                  className="title2Color"
                  title={props.campaigns?.configurations?.emailSettings?.from}
                  dataTestId="emailSettings-from"
                >
                  {trunacteString(props.campaigns?.configurations?.emailSettings?.from, 17)}
                </MdTypography>
              </div>
              <div className="flex flex-col g-items" title={props.campaigns?.configurations?.emailSettings?.replyTo}>
                <MdTypography variant={'smallTextRegular'} className="embeddedText">
                  Reply-To
                </MdTypography>
                <MdTypography variant={'pMedium'} className="title2Color" dataTestId="emailSettings-replyTo">
                  {trunacteString(props.campaigns?.configurations?.emailSettings?.replyTo, 25)}
                </MdTypography>
              </div>
            </div>
          </div>
        </div>
        <div className="share-campaign">
          <MdTypography variant={'h4Light'} className="embeddedTextTitle">
            Share Campaign
          </MdTypography>
          <div className="share-socials">
            <div className="heading flex items-end">
              <MdTypography variant={'h6Light'} className="embeddedTextTitle">
                Share on Social Media
              </MdTypography>
              <MdTypography variant={'captionRegular'}>[Click to copy]</MdTypography>
            </div>
            <div className="details flex items-center">
              <div className="social-link">
                <FacebookShareButton url={`${webUrl}/join/${props.campaigns?._id}`} title="Facebook">
                  <img src={'/assets/fb-social.svg'} alt="" />
                </FacebookShareButton>
                <p className="showTip text-white font-semibold">Facebook</p>
              </div>
              <div className="social-link ">
                <TwitterShareButton title="X" url={`${webUrl}/join/${props.campaigns?._id}`}>
                  <Xlogo />
                </TwitterShareButton>
                <p className="showTip text-white font-semibold">X</p>
              </div>
              <div className="social-link">
                <LinkedinShareButton title="Linkedin" url={`${webUrl}/join/${props.campaigns?._id}`}>
                  <img src={'/assets/in-social.svg'} alt="" />
                </LinkedinShareButton>
                <p className="showTip text-white font-semibold">LinkedIn</p>
              </div>
              <div className="social-link">
                <FacebookMessengerShareButton url={`${webUrl}/join/${props.campaigns?._id}`} title={'Instagram'}>
                  <img src={'/assets/msg-social.svg'} alt="" />
                </FacebookMessengerShareButton>
                <p className="showTip text-white font-semibold">Messenger</p>
              </div>
              <div className="social-link">
                <WhatsappShareButton url={`${webUrl}/join/${props.campaigns?._id}`} title={'Whatsapp'}>
                  <img src={'/assets/wa-socia.svg'} alt="" />
                </WhatsappShareButton>
                <p className="showTip text-white font-semibold">Whatsapp</p>
              </div>
            </div>
          </div>
          <div className="share-landing-page">
            <div className="heading">
              <MdTypography variant={'h6Medium'} className="embeddedTextTitle">
                Edit Join Page
              </MdTypography>
            </div>
            <div className="details flex items-center">
              <MdTypography variant={'smallTextRegular'}>
                {trunacteString(`${webUrl}/join/${userName}/${campaignTitle}`, 35)}
              </MdTypography>
              <div className="landingpage_comp">
                <Link
                  to={{
                    pathname: `/join/${userName}/${campaignTitle}/admin-edit`,
                    search: `?edit=true&id=${campaignId}`,
                  }}
                >
                  <MdTypography variant={'btnLight'}>Edit</MdTypography>
                </Link>
                <Popconfirm
                  title="Would you like to redirect your users to a particular link after subscribing?"
                  onConfirm={props.popConfirm}
                  onCancel={props.popCancel}
                  okText="Yes"
                  cancelText="No"
                  placement="bottomLeft"
                >
                  <div className="landingpage_copy-link" data-test="redirect-confirm">
                    <MdTypography variant={'btnLight'} className="title2Color">
                      Copy link
                    </MdTypography>
                    <img src={copyIcon} alt="copy icon" className="img-fluid ml-1" />
                  </div>
                </Popconfirm>
                <RedirectUrlInput
                  imVisible={props.inputUrlModal}
                  setImVisible={props.setInputUrlModal}
                  campaignId={props.campaignId}
                  context="joinPage"
                  data={props.data}
                  campaignTitle={campaignTitle}
                />
              </div>
            </div>
          </div>
          <div className="share-embedded-form">
            <div className="heading">
              <MdTypography variant={'h6Medium'} className="embeddedTextTitle">
                Embedded Form
              </MdTypography>
            </div>
            <div className="details flex items-center">
              <MdTypography variant={'smallTextRegular'} className="shareLink embeddedText">
                Build and embed signup forms in your site
              </MdTypography>
              <MdTypography
                variant={'btnRegular'}
                className="shareLink ml-2 pb-2"
                onClick={() => window.location.replace(`${webUrl}/campaign/${props.campaigns?._id}/forms`)}
              >
                Take me there
              </MdTypography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPopconfirm(MdCampaignInformation, 'obj');
