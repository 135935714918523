import { Tooltip } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../../../asset/Icons/HomeIcon.svg';
import { ReactComponent as CampaignIcon } from '../../../asset/Icons/campaignIcon.svg';
import { ReactComponent as SidbarCollapsableIcon } from '../../../asset/Icons/Component 27/iconoir_sidebar-collapse.svg';
import { ReactComponent as ContactsIcon } from '../../../asset/Icons/contactsIcon.svg';
import { ReactComponent as Emailcon } from '../../../asset/Icons/emailcon.svg';
import { ReactComponent as OptinPageIcon } from '../../../asset/Icons/optinPageIcon.svg';
import { ReactComponent as SideNavBillingIcon } from '../../../asset/Icons/sidenav-billings.svg';
import { ReactComponent as SideNavSettingsIcon } from '../../../asset/Icons/sidenav-settings.svg';
import { getWidth } from '../shared-functions/getSideBarWidth';
import '../../../styles/sidebar.scss';
import MdGlobalButton from '../../../components/global/Button';
import AdditionIcon from '../../../asset/Icons/AdditionIcon';
import { MdTypography } from '../../../components/global';
import { ReactComponent as LandingPageicon } from '../../../asset/Icons/LandingPageicon.svg';

const Sidebar = ({ activeSideBar, setActiveSideBar, disableEmail, disableUser }) => {
  const location = useLocation();
  const locationName =
    location.pathname.includes('/plans') || location.pathname.includes('/payment')
      ? 'settings/account/plans'
      : location.pathname.includes('/settings')
      ? 'settings/account/details'
      : location.pathname.split('/')[1];

  const sidebarNavItems = [
    {
      display: 'Dashboard',
      icon: <HomeIcon />,
      to: 'dashboard',
      id: 'sidebar-dashboard',
    },

    {
      display: 'Instant Emails',
      icon: <Emailcon />,
      to: 'instant-emails',
      id: 'sidebar-instant-emails',
    },

    {
      display: 'Drip Campaigns',
      icon: <CampaignIcon />,
      to: 'campaigns',
      id: 'sidebar-campaigns',
    },

    {
      display: 'Contacts',
      icon: <ContactsIcon />,
      to: 'contacts',
      id: 'sidebar-contacts',
    },

    {
      display: 'Opt-in Pages',
      icon: <OptinPageIcon />,
      to: 'opt-in-pages',
      id: 'sidebar-opt-in',
    },

    {
      display: 'Landing Pages',
      icon: <LandingPageicon />,
      to: 'landing-pages',
      id: 'sidebar-landing',
    },

    {
      display: 'Plans & Billing',
      icon: <SideNavBillingIcon />,
      to: 'settings/account/plans',
      id: 'sidebar-billing',
    },

    {
      display: 'Settings',
      icon: <SideNavSettingsIcon />,
      to: 'settings/account/details',
      id: 'sidebar-settings',
    },
  ];

  const disableConditions = disableEmail || disableUser;

  return (
    <>
      <div
        className="sidebar"
        style={{
          width: getWidth(activeSideBar),
          display: activeSideBar.topNavOpen ? '' : '',
          top: disableConditions ? '2.5rem' : '0',
        }}
      >
        <div>
          <Link to="/" className="sidebar__logo" style={{ width: () => activeSideBar.getWidth() }}>
            <img className="logo " src="/assets/logo.svg" alt="logo"></img>
            <div className="centered">
              <span className={`logo-title ${activeSideBar.active ? 'revealMe' : 'hideMe'}`}>MailDrip</span>
            </div>
          </Link>

          <div className="sidebar__menu">
            <Tooltip
              placement="rightTop"
              title={`${!activeSideBar.active ? 'Open navigation' : 'Hide navigation'}`}
              arrow
            >
              <div>
                <button
                  className="mr-3"
                  onClick={() => setActiveSideBar({ ...activeSideBar, active: !activeSideBar.active })}
                >
                  <SidbarCollapsableIcon />
                </button>
              </div>
            </Tooltip>
            {activeSideBar.active ? (
              // <div className= {activeSideBar.active ? 'revealMe' : 'hideMe'}>
              <div>
                <Link to="/create-new">
                  <MdGlobalButton className="btn-create" loading={false}>
                    <MdTypography variant={'btnRegular'} className="create-text">
                      Create New
                    </MdTypography>
                    <AdditionIcon />
                  </MdGlobalButton>
                </Link>
              </div>
            ) : (
              <Tooltip placement="rightTop" title={`${!activeSideBar.active ? 'Create New' : ''}`} arrow>
                <div className={!activeSideBar.active ? 'revealMe' : 'hideMe'}>
                  <Link to="/create-new">
                    <MdGlobalButton className="btn-hide" loading={false}>
                      <div className="create-text">
                        <AdditionIcon />
                      </div>
                    </MdGlobalButton>
                  </Link>
                </div>
              </Tooltip>
            )}
            <div className="sidebar__menu__list">
              {sidebarNavItems
                .filter((item) => item !== false)
                .map((item, index) => (
                  <Link to={`/${item.to}`} key={index}>
                    <Tooltip placement="rightTop" title={item.display} arrow>
                      <div
                        product-tutor-id={item.id}
                        className={`sidebar__menu__item ${locationName === item.to && 'active'}`}
                        style={{ width: '100%' }}
                      >
                        <div className="sidebar__menu__item__icon">
                          {/* <img src={item.icon} alt="" /> */}
                          {item.icon}
                        </div>
                        <div
                          className={`sidebar__menu__item__text sidebar-mobile ${
                            activeSideBar.active ? 'revealMe' : 'hideMe'
                          }`}
                        >
                          {item.display}
                        </div>
                      </div>
                    </Tooltip>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
