import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Divider, Dropdown } from 'antd';
import { jobContext } from 'contexts/job';
import { SET_JOBS } from 'contexts/job/reducer/types';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Notification from '../../../../src/asset/Icons/Notification-icon.svg';
import Hamburger from '../../../../src/asset/Icons/hamburger-new.svg';
import AdditionIcon from '../../../asset/Icons/AdditionIcon';
import { ReactComponent as HomeIcon } from '../../../asset/Icons/HomeIcon.svg';
import { BackbuttonIcon } from '../../../asset/Icons/Backbutton';
import { ReactComponent as ArrowDownIcon } from '../../../asset/Icons/arrow-down.svg';
import { ReactComponent as CampaignIcon } from '../../../asset/Icons/campaignIcon.svg';
import { ReactComponent as ContactsIcon } from '../../../asset/Icons/contactsIcon.svg';
import { ReactComponent as Emailcon } from '../../../asset/Icons/emailcon.svg';
import { ReactComponent as InformationIcon } from '../../../asset/Icons/informationIcon.svg';
import { ReactComponent as Logout } from '../../../asset/Icons/logout.svg';
import { ReactComponent as Newspaper } from '../../../asset/Icons/newspaper.svg';
import { ReactComponent as OptinPageIcon } from '../../../asset/Icons/optinPageIcon.svg';
import { ReactComponent as LandingPageIcon } from '../../../asset/Icons/UsersIcon.svg';
import { ReactComponent as SettingsIcon } from '../../../asset/Icons/settingsIcon.svg';
import { ReactComponent as SideNavBillingIcon } from '../../../asset/Icons/sidenav-billings.svg';
import { ReactComponent as SideNavSettingsIcon } from '../../../asset/Icons/sidenav-settings.svg';
import LoadingScreen from '../../../components/LoadingScreen';
import { useGetWorkspaceListQuery, useSwitchWorkspaceMutation } from '../../../redux/featureApi/teamCollabApiSlice';
import { logOutUser, selectUserAccessLevel, setWorkspaceDetails } from '../../../redux/featureSlices/authSlice';
import { showToastMessage } from '../../../utils/Toast';
import { trunacteString } from '../../../utils/generics';
import { errorMessage } from '../../../utils/getErrors';
import MdGlobalButton from '../../global/Button';
import MdTypography from '../../global/Typography';
import { getWidth } from '../shared-functions/getSideBarWidth';
import { growthBookId } from '../../../utils/constants';

import { useFeatureIsOn } from '@growthbook/growthbook-react';

export const AuthNav = ({
  data: userDetails,
  userActiveWorkSpace,
  activeSideBar,
  setActiveSideBar,
  disableEmail,
  disableUser,
}) => {
  // eslint-disable-next-line
  const landingPage = useFeatureIsOn(growthBookId.landingPageDesign);
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation =
    location.pathname.includes('/plans') || location.pathname.includes('/payment')
      ? 'settings/account/plans'
      : location.pathname.includes('/settings')
      ? 'settings/account/details'
      : location.pathname.split('/')[1];
  const [mobileNavActive, setMobileNavActive] = useState(false);
  const [containMultiple, setContainMultiple] = useState(false);
  const dispatch = useDispatch();

  const displayLocation = () =>
    currentLocation.includes('settings') ? 'settings' : emailBuilderPath ?? currentLocation.split('-').join(' ');

  const emailBuilderPath = location.pathname.split('/').includes('speditor') ? 'Email Builder' : undefined;
  const { dispatch: jobsDispatch } = useContext(jobContext);
  /* eslint-disable */

  const {
    data: workspaceList,
    // isLoading: isWorkspaceLoading,
    isSuccess,
  } = useGetWorkspaceListQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [switchWorkspace] = useSwitchWorkspaceMutation();
  const accessLevel = useSelector(selectUserAccessLevel) || userActiveWorkSpace;
  const currentWorkspace = workspaceList?.find((space) => space?.workspaceName === accessLevel?.workspace);

  // console.log({ accessLevel, userActiveWorkSpace });

  useEffect(() => {
    isSuccess && dispatch(setWorkspaceDetails(workspaceList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceList]);

  const pathArray = ['/instant-emails', '/contacts', '/campaigns', '/settings/account/:tab'];

  useEffect(() => {
    const currentUrl = location?.pathname;
    const containsMultipleSlashes = currentUrl.match(/\//g).length;
    if (containsMultipleSlashes >= 2 || pathArray.includes(currentUrl)) {
      setContainMultiple(true);
    } else {
      setContainMultiple(false);
    }
  }, [location]);
  const handleLogOut = () => {
    dispatch(logOutUser());
    jobsDispatch({
      type: SET_JOBS,
      payload: [],
    });
    navigate('/login');
  };

  const switchWorkspaceHandler = async (id) => {
    switchWorkspace(id)
      .unwrap()
      .then((resp) =>
        showToastMessage({
          type: 'success',
          title: 'Success',
          description: resp.message ?? '',
        })
      )
      .catch((error) => {
        const errMsg = errorMessage(error);
        showToastMessage({
          type: 'error',
          title: 'Error',
          description: errMsg,
        });
      });
  };

  const getJoinedWorkspace = (ws) => (
    <>
      <div className="profileDropdown" onClick={() => switchWorkspaceHandler(ws.workspaceId)}>
        <div className="flex item-center accountDetails">
          <div className="centered">
            <img className="mr-3" src={ws.picture ?? '/assets/avatar.svg'} alt="profile-img"></img>
          </div>
          <div className="flex flex-col">
            <span>{ws.workspaceName}</span>
            <p>{`${ws.members} ${ws.members <= 1 ? 'member' : 'members'}`}</p>
          </div>
        </div>
      </div>
    </>
  );

  const teamColl = workspaceList?.map((ws, i) => {
    return {
      label: getJoinedWorkspace(ws),
      key: i,
      disabled: currentWorkspace?.workspaceId === ws?.workspaceId,
    };
  }) || [
    {
      label: <LoadingScreen />,
      key: 'loading',
    },
  ];

  const menuProps = {
    items: [
      ...teamColl,
      {
        key: '1100',
        label: <Divider style={{ margin: '0px' }} />,
      },
      {
        key: '1101',
        label: (
          <div className="menuItem">
            <Link className="flex items-center" to="/settings/account/details">
              <SettingsIcon className="mx-3" />
              <span className="menuItem_item">Settings & Plans</span>
            </Link>
          </div>
        ),
      },
      {
        key: '1102',
        label: (
          <div className="menuItem">
            <Link className="flex items-center" to={'/whats-new'}>
              <Newspaper className="mx-3" />
              <span className="menuItem_item"> What's New</span>
            </Link>
          </div>
        ),
      },
      {
        key: '1103',
        label: (
          <div className="menuItem">
            <a
              className="flex items-center"
              href={'https://maildripio.notion.site/Help-Center'}
              rel="noopener noreferrer"
              target="_blank"
            >
              <InformationIcon className="mx-3" />
              <span className="menuItem_item">Help Center</span>
            </a>
          </div>
        ),
      },
      {
        key: '1104',
        label: (
          <div className="menuItem" data-test="logout-user-id">
            {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="flex items-center menu-item-danger" style={{ textDecoration: 'none' }} onClick={handleLogOut}>
              <Logout className="mx-3" />
              <span className="menuItem_item logOut">Log Out</span>
            </a>
          </div>
        ),
      },
    ],
    // onClick: handleMenuClick,
  };

  const sidebarNavItems1 = [
    {
      display: 'Dashboard',
      icon: <HomeIcon />,
      to: 'dashboard',
    },

    {
      display: 'Instant Emails',
      icon: <Emailcon />,
      to: 'instant-emails',
    },

    {
      display: 'Drip Campaigns',
      icon: <CampaignIcon />,
      to: 'campaigns',
    },

    {
      display: 'Contacts',
      icon: <ContactsIcon />,
      to: 'contacts',
    },

    {
      display: 'Opt-in Pages',
      icon: <OptinPageIcon />,
      to: 'opt-in-pages',
      id: 'sidebar-opt-in',
    },

    {
      display: 'Landing Pages',
      icon: <LandingPageIcon />,
      to: 'landing-pages',
      id: 'sidebar-landing',
    },

    {
      display: 'Plans & Billing',
      icon: <SideNavBillingIcon />,
      to: 'settings/account/plans',
      id: 'sidebar-billing',
    },

    {
      display: 'Settings',
      icon: <SideNavSettingsIcon />,
      to: 'settings/account/details',
      id: 'sidebar-settings',
    },
  ];

  const tutorButton = document.querySelector('.uf-button-main');

  const hideWalkThrough = location.pathname != '/dashboard';
  const hiddenCondition = disableEmail || disableUser;
  return (
    <>
      <nav
        className="authNavBar"
        style={{ width: `calc(100% - ${getWidth(activeSideBar)})`, top: hiddenCondition ? '2.5rem' : '0' }}
      >
        <div className="content flex-space-btw">
          <div className="mobileController flex">
            <div className="flex-space-btw">
              <Link to="/" className="flex logo_container-desktop">
                <img className="logo" src="/assets/logo.svg" alt="logo"></img>
                <div className="centered">
                  <span className="title ml-1 font18">MailDrip</span>
                </div>
              </Link>
              <div
                onClick={() => setActiveSideBar({ ...activeSideBar, topNavOpen: !activeSideBar.topNavOpen })}
                className="centered pointer hamburger"
              >
                <MenuOutlined />
              </div>
            </div>
            {activeSideBar.topNavOpen && (
              <div className="centered navLinks">
                <div id="mobileNav" className="flex">
                  {containMultiple ? (
                    <div className="contain-multiple-container" onClick={() => navigate(-1)}>
                      <BackbuttonIcon className="back-button-icon" />
                      <MdTypography variant={'h5Medium'} className="capitalize clt">
                        {displayLocation()}
                      </MdTypography>
                    </div>
                  ) : (
                    <MdTypography variant={'h5Medium'} className="capitalize clt">
                      {displayLocation()}
                    </MdTypography>
                  )}
                  {/* <Link to={"./"}>Contact</Link> */}
                </div>
              </div>
            )}
          </div>
          {activeSideBar.topNavOpen && (
            <div className="centered navLinks">
              <div id="mobileNav" className="flex items-center">
                {/* <Link to={'/campaigns'}>Campaign</Link>
              <div className="flex align-items-center">
                <Link className="" to={'/contacts'}>
                  Contact
                  <span className="tomato ml-2">new</span>
                </Link>
              </div> */}
                {!hideWalkThrough && (
                  <div className="product-tutor-button mr-8 cursor-pointer" id="tourbtn">
                    <p>View Walkthrough</p>
                  </div>
                )}

                {/* <div className="flex align-items-center">
                  <Link className="whats_new flex align-items-center" to={'/whats-new'}>
                    <img alt="whahts-new" src="/assets/fluent_megaphone-16-filled.svg" />
                    What's New
                  </Link>
                </div>
                {/* <Dropdown overlay={profileDropdown}> */}
                <Dropdown menu={menuProps} overlayClassName="emmaccen" overlayStyle={{ width: '273px' }}>
                  <div
                    className="flex pointer current_loggedin_user items-center"
                    data-test="top-nav-workspace-info"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img
                      className="logo radius-50"
                      src={currentWorkspace?.picture ?? '/assets/avatar.svg'}
                      alt="workspace_profile_picture"
                    ></img>
                    <div title={currentWorkspace?.workspaceName}>
                      <p>{currentWorkspace?.workspaceName}</p>
                      <p>{trunacteString(userDetails?.email, 18)}</p>
                    </div>
                    <ArrowDownIcon />
                  </div>
                </Dropdown>
              </div>
            </div>
          )}
        </div>
      </nav>

      <nav className="authNavBar-new-mobile">
        <div className="hamburger" onClick={() => setMobileNavActive(true)}>
          <img src={Hamburger} alt="hamburger" />
        </div>

        <div className="current-page">
          <MdTypography variant={'h5Medium'} className="capitalize clt">
            {displayLocation()}
          </MdTypography>
        </div>

        <div className="right-side">
          <img src={Notification} alt="nNotification icon" />
          <Dropdown menu={menuProps} overlayClassName="emmaccen" overlayStyle={{ width: '273px' }}>
            <div
              className="flex pointer current_loggedin_user items-center"
              data-test="top-nav-workspace-info"
              onClick={(e) => e.preventDefault()}
            >
              <img
                className="logo radius-50 workspace-img"
                src={currentWorkspace?.picture ?? '/assets/avatar.svg'}
                alt="workspace_profile_picture"
              ></img>
            </div>
          </Dropdown>
        </div>

        <div className={`navigation-container ${mobileNavActive ? 'slide-in' : 'slide-out'}`}>
          <div className="navigation">
            <div className="head">
              <Link to="/" className="flex logo_container-desktop">
                <img className="logo" src="/assets/logo.svg" alt="logo"></img>
                <div className="centered">
                  <span className="title ml-1 font18">MailDrip</span>
                </div>
              </Link>

              <div onClick={() => setMobileNavActive(false)}>
                <CloseOutlined style={{ fontSize: '20px' }} />
              </div>
            </div>

            <div className="create-new-section">
              <Link to="/create-new" onClick={() => setMobileNavActive(false)}>
                <MdGlobalButton className="btn-create" loading={false}>
                  <MdTypography variant={'btnRegular'} className="create-text">
                    Create New
                  </MdTypography>
                  <AdditionIcon />
                </MdGlobalButton>
              </Link>
            </div>

            <div className="menu-container">
              <div className="menu-items">
                {sidebarNavItems1.map((item, index) => {
                  const isActive = currentLocation == item.to;
                  return (
                    <Link to={`/${item.to}`} key={index} onClick={() => setMobileNavActive(false)}>
                      <div className={`item ${isActive && 'primaryColor'}`}>
                        <span className="item-icon">{item.icon}</span>
                        <span className="item-display">{item.display}</span>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>

            <div className="logged-in-user">
              <p>Logged in as {currentWorkspace?.workspaceName}</p>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
