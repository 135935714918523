import { Modal, Form, Input } from 'antd';
import { MdGlobalButton, MdTypography } from 'components/global';
import { LandingPageIcon } from 'asset/Icons/LandingPageIcon';
import { useNavigate } from 'react-router-dom';
import { useCreateLandingPageMutation } from 'redux/featureApi/landingPageApiSlice';
import { showToastMessage } from 'utils/Toast';
import { mustNotContainSpecialCharacters } from 'utils/formRules';

const MdCreateNewLandingPage = ({ imvisible, setImvisible, landingPagesTitle }) => {
  const [landingPageForm] = Form.useForm();
  const [createLandingPage, { isLoading: creatingNewLandingPage }] = useCreateLandingPageMutation();
  const navigate = useNavigate();
  const createLandingPageFn = () => {
    const title = landingPageForm.getFieldValue().title.trim();

    const titleExists = landingPagesTitle.some((item) => item.toLowerCase() === title.toLowerCase());

    if (titleExists) {
      return showToastMessage({
        type: 'error',
        title: 'Error',
        description: 'Landing page with this title already exists',
      });
    }

    const payLoad = {
      title,
      html: '',
      css: '',
      ampHtml: '',
      juicedHtml: '',
    };

    createLandingPage(payLoad)
      .unwrap()
      .then((res) => {
        const landingPageId = res.data.landingPage._id;
        navigate(`/landing-pages/${landingPageId}/select-template`, { state: { landingPageId, title } });
      });
  };

  return (
    <Modal
      open={imvisible?.id === 'createNewLandingPage'}
      onCancel={() => setImvisible({ id: '' })}
      footer={null}
      className="emmaccen create-campaign-modal"
      centered
    >
      <div>
        <MdTypography variant={'h5Medium'} className="text-center">
          New Landing Page
        </MdTypography>
        <div className="modalAlert">
          <div>
            <MdTypography variant={'btnLight'} className="modalText">
              This will be created as a new landing page. Please enter the title of the landing page you would like to
              create and proceed.
            </MdTypography>
          </div>
          <div className="modalImg">
            <div className="illustration">
              <LandingPageIcon />
            </div>
          </div>
        </div>
        <div data-test="landing-page-form">
          <Form
            size="large"
            form={landingPageForm}
            onFinish={createLandingPageFn}
            onFinishFailed={() => null}
            autoComplete="off"
            layout={'vertical'}
            requiredMark={false}
          >
            <label className="mb-2 formLabel">Landing page title</label>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please give your landing page a name',
                  whitespace: true,
                },
                {
                  ...mustNotContainSpecialCharacters[0]
                }
              ]}
            >
              <Input />
            </Form.Item>

            <div className="centered mt-10">
              <MdGlobalButton
                id={'primary_lg'}
                type="primary"
                htmlType="submit"
                data-test="create-landing-page-submit"
                loading={creatingNewLandingPage}
              >
                <MdTypography variant={'h6Medium'} style={{ color: '#ffffff' }}>
                  Proceed
                </MdTypography>
              </MdGlobalButton>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default MdCreateNewLandingPage;
