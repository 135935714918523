import { message } from 'antd';
import React, { useState } from 'react';
import { cleanString } from 'utils/generics';
import { useEditCampaignLandingPageMutation, useGetCampaignLandingPageQuery } from 'redux/featureApi/campaignApiSlice';

const withPopconfirm = (Component, idType) => {
  const PopConfirm = (props) => {
    const [inputUrlModal, setInputUrlModal] = useState(false);

    const [redirect, setRedirect] = useState(false);
    const { data: campaignData } = props;
    const campaignId = campaignData?._id;

    const [postPublishPageRequest] = useEditCampaignLandingPageMutation();
    const { data: landingPageData } = useGetCampaignLandingPageQuery(campaignId, {
      refetchOnMountOrArgChange: true,
    });


    React.useEffect(() => {
      const payload = { ...landingPageData?.data };
      if (redirect === true && landingPageData) {
        payload.redirect_url = true;
      
        postPublishPageRequest({ payload, campaignId })
          .unwrap()
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        payload.redirect_url = false;
        postPublishPageRequest({ payload, campaignId })
          .unwrap()
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, [redirect, campaignId, landingPageData, postPublishPageRequest]);

    const popConfirm = (e) => {
      setInputUrlModal(true);
      setRedirect(true);
    };

    const popCancel = (e) => {
      const { data: campaignData } = props;
      const campaignTitle = cleanString(campaignData?.name, '-');
      const campaignId = campaignData?._id;
      const { user } = JSON.parse(localStorage.getItem('userDetails'));
      const userName = user.username;
      const JOIN_CAMPAIGN_REDIRECT = `${window.location.origin}/${userName}/join/${campaignTitle}?id=${campaignId}`;
      navigator.clipboard.writeText(JOIN_CAMPAIGN_REDIRECT);
      message.success('Link copied successfully');
      setRedirect(false);
    };
    return (
      <Component
        {...props}
        popConfirm={popConfirm}
        popCancel={popCancel}
        inputUrlModal={inputUrlModal}
        setInputUrlModal={setInputUrlModal}
      />
    );
  };
  return PopConfirm;
};

export default withPopconfirm;
