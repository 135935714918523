import { Collapse } from 'antd';
import { MdTypography } from 'components/global';
import { instantEmailsActionTypes, mailTypes } from '../../../utils/constants';
import Step1Subscribers from './Instant Email Steps/Step1Subscribers';
import Step2FromandReplyTo from './Instant Email Steps/Step2FromandReplyTo';
import Step3Subject from './Instant Email Steps/Step3Subject';
import Step4DesignEmail from './Instant Email Steps/Step4DesignEmail';

const InstantEmailSteps = ({
  // Variables
  completeStep,
  contactGroups,
  fetchingContactGroups,
  editAction,
  email,
  emailSubject,
  fromAndReplyToForm,
  fromName,
  groupsAdded,
  setContactGroups,
  refetchSingleInstantEmail,
  refetchContactGroupsData,
  handleSaveFromAndReplyTo,
  handleSaveInstantEmailContact,
  handleUpdateEmailSubject,
  instantEmailTitle,
  isEditingMail,
  isValidSubject,
  isFromAndReplyToValidated,
  mailId,
  saveContactForm,
  savedGroupsToInstantEmailsLoading,
  setFromName,
  setGroupsAdded,
  savedGroup,
  setImVisible,
  imVisible,
  subscribersCount,
  subjectForm,
  // Functions
  setEmailSubject,
  setIsFromAndReplyToValidated,
  setIsValidSubject,
  addedRecipients,
}) => {
  const { Panel } = Collapse;

  const CollapsePanelHeader = ({ title, subTitle }) => (
    <div className="flex flex-col mt-2 mb-2 collapseHeader">
      <div className="flex items-center mb-3">
        <MdTypography variant={'h6Light'}>{title}</MdTypography>
      </div>
      <MdTypography variant={'smallTextRegular'}>{subTitle}</MdTypography>
    </div>
  );

  return (
    <Collapse accordion expandIconPosition="end">
      <Panel
        data-test="sub-paneltest"
        extra={
          <div
            data-test="subscribers-checkboxtest"
            checked={completeStep.stepOne}
            style={{
              borderRadius: '16px',
              background: completeStep.stepOne ? '#37B34A' : '#C7C7C7',
              color: 'white',
              padding: '4px 16px',
            }}
          >
            {completeStep.stepOne ? 'ready' : 'unset'}
          </div>
        }
        header={<CollapsePanelHeader title="To" subTitle="Select a subscribers' list" />}
        key="1"
        showArrow={false}
      >
        <Step1Subscribers
          saveContactForm={saveContactForm}
          setImVisible={setImVisible}
          imVisible={imVisible}
          contactGroups={contactGroups}
          refetchContactGroupsData={refetchContactGroupsData}
          refetchSingleInstantEmail={refetchSingleInstantEmail}
          fetchingContactGroups={fetchingContactGroups}
          groupsAdded={groupsAdded}
          setGroupsAdded={setGroupsAdded}
          subscribersCount={subscribersCount}
          savedGroup={savedGroup}
          savedGroupsToInstantEmailsLoading={savedGroupsToInstantEmailsLoading}
          handleSaveInstantEmailContact={handleSaveInstantEmailContact}
          addedRecipients={addedRecipients}
        />
      </Panel>

      <Panel
        data-test="fromandreply-paneltest"
        className="fromandreply-panel"
        extra={
          <div
            data-test="fromandreply-checkboxtest"
            checked={completeStep.stepTwo}
            style={{
              borderRadius: '16px',
              background: completeStep.stepTwo ? '#37B34A' : '#C7C7C7',
              color: 'white',
              padding: '4px 16px',
            }}
          >
            {completeStep.stepTwo ? 'ready' : 'unset'}
          </div>
        }
        header={
          <CollapsePanelHeader
            title={'From and “reply-to”'}
            subTitle="Set a custom persona and reply-to address for your email"
            paid
          />
        }
        key="2"
        showArrow={false}
      >
        <Step2FromandReplyTo
          fromAndReplyToForm={fromAndReplyToForm}
          fromName={fromName}
          setFromName={setFromName}
          emailSubject={emailSubject}
          handleSaveFromAndReplyTo={handleSaveFromAndReplyTo}
          isEditingMail={isEditingMail}
          editAction={editAction}
          isFromAndReplyToValidated={isFromAndReplyToValidated}
          setIsFromAndReplyToValidated={setIsFromAndReplyToValidated}
        />
      </Panel>

      <Panel
        data-test="email-subject-panel-test"
        className="email-subject-panel"
        extra={
          <div
            checked={completeStep.stepThree}
            data-test="subject-checkboxtest"
            style={{
              borderRadius: '16px',
              background: completeStep.stepThree ? '#37B34A' : '#C7C7C7',
              color: 'white',
              padding: '4px 16px',
            }}
          >
            {completeStep.stepThree ? 'ready' : 'unset'}
          </div>
        }
        header={
          <CollapsePanelHeader
            title="Email Subject"
            subTitle="Craft an engaging email subject line to capture your audiences' attention"
          />
        }
        key="3"
        showArrow={false}
      >
        <Step3Subject
          subjectForm={subjectForm}
          fromName={fromName}
          emailSubject={emailSubject}
          setEmailSubject={setEmailSubject}
          handleUpdateEmailSubject={handleUpdateEmailSubject}
          isEditingMail={isEditingMail}
          editAction={editAction}
          isValidSubject={isValidSubject}
          setIsValidSubject={setIsValidSubject}
        />
      </Panel>

      <Panel
        data-test="design-email-panel-test"
        className="design-email-panel"
        extra={
          <div
            checked={completeStep.stepFour}
            style={{
              borderRadius: '16px',
              background: completeStep.stepFour ? '#37B34A' : '#C7C7C7',
              color: 'white',
              padding: '4px 16px',
            }}
          >
            {completeStep.stepFour ? 'ready' : 'unset'}
          </div>
        }
        header={
          <CollapsePanelHeader title="Email body" subTitle="Create a simple text email or use an email builder" />
        }
        key="4"
        showArrow={false}
      >
        <Step4DesignEmail
          instantEmailTitle={instantEmailTitle}
          email={email}
          mailId={mailId}
          emailSubject={emailSubject}
          mailTypes={mailTypes}
          setImVisible={setImVisible}
          instantEmailsActionTypes={instantEmailsActionTypes}
        />
      </Panel>
    </Collapse>
  );
};

export default InstantEmailSteps;
