import * as Sentry from '@sentry/react';
import 'grapesjs/dist/css/grapes.min.css';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import App from './App';
import './tailwind.css';
import { HelmetProvider } from 'react-helmet-async';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_INGEST_URL,
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/app\.maildrip\.io/,
    /^https:\/\/maildrip-client-server-pr-\d+-[\w\d-]+\.herokuapp\.com/,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const node_env = process.env.REACT_APP_NODE_ENV;

if (node_env?.toUpperCase() === 'PRODUCTION') {
  const s = document.getElementsByTagName('script')[0];

  // clarity
  const clarity = document.createElement('script');
  // Begin clarity Asynchronous Code
  clarity.innerHTML = `
 (function(c,l,a,r,i,t,y){
  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
  t=l.createElement(r);
  t.async=1;
  t.src="https://www.clarity.ms/tag/"+i;
  y=l.getElementsByTagName(r)[0];
  y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "hmda2scve6");
`;
  // End clarity Asynchronous Code
  clarity.type = 'text/javascript';
  s.parentNode.insertBefore(clarity, s);

  // GOOGLE ANALYTICS
  // Global site tag (gtag.js) - Google Analytics begin
  const g = document.createElement('script');
  g.type = 'text/javascript';
  g.async = true;
  g.src = 'https://www.googletagmanager.com/gtag/js?id=G-ZLEFSFBW0P';
  s.parentNode.insertBefore(g, s);

  const g2 = document.createElement('script');
  g2.innerHTML = `  window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-ZLEFSFBW0P');
`;
  s.parentNode.insertBefore(g2, s);
  // Global site tag (gtag.js) - Google Analytics end

  // // <!-- Start of Fresh Desk Script Code -->

  // const fwChat = document.createElement('script');
  // fwChat.type = 'text/javascript';
  // fwChat.async = true;
  // fwChat.src = '//fw-cdn.com/10124316/3433280.js';
  // fwChat.setAttribute('chat', 'true');
  // s.parentNode.insertBefore(fwChat, s);
  // // <!-- End of Fresh Desk Script Code -->

  // <!-- Start of Chatty app Script Code -->
  // const chaty = document.createElement('script');
  // chaty.type = 'text/javascript';
  // chaty.async = true;
  // chaty.src = 'https://cdn.chaty.app/pixel.js?id=tCjmH0Dm';
  // chaty.setAttribute('id', 'pixel-chaty');
  // s.parentNode.insertBefore(chaty, s);

  // Start of Formilla Script Code
  const formilla = document.createElement('script');
  formilla.type = 'text/javascript';
  formilla.async = true;
  formilla.innerHTML = `
    (function () {
      var head = document.getElementsByTagName("head").item(0);
      var script = document.createElement("script");
  
      var src = (document.location.protocol == 'https:'
          ? 'https://www.formilla.com/scripts/feedback.js'
          : 'http://www.formilla.com/scripts/feedback.js');
  
      script.setAttribute("type", "text/javascript");
      script.setAttribute("src", src); script.setAttribute("async", true);
  
      var complete = false;
  
      script.onload = script.onreadystatechange = function () {
          if (!complete && (!this.readyState
              || this.readyState == 'loaded'
              || this.readyState == 'complete')) {
              complete = true;
              Formilla.guid = 'cs54460d-13e0-48e8-b906-ed7ecb3d036c';
              Formilla.loadWidgets();
          }
      };
  
      head.appendChild(script);
    })();
  `;

  s.parentNode.insertBefore(formilla, s);

  //   const linkMink = document.createElement('script');
  //   linkMink.type = 'text/javascript';
  //   linkMink.async = true;
  //   linkMink.src = 'https://cdn.linkmink.com/lm-js/2.3.1/lm.js';

  //   s.parentNode.insertBefore(linkMink, s);

  //   const linkMink2 = document.createElement('script');
  //   linkMink2.innerHTML = ` if (typeof LinkMink==="function") {
  //   const linkMink = LinkMink('pub_live_V8RF8N70WWFGeP4wXNAM');
  //   linkMink.initTracking().then();
  //   }
  // `;
  //   s.parentNode.insertBefore(linkMink2, s);
  // <!-- End of Fresh Desk Script Code -->
}

// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(<App />);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // <StrictMode>
  <HelmetProvider>
    <App />
  </HelmetProvider>
  // </StrictMode>
);
